import React, { ReactNode } from 'react';

import { BackgroundActions } from './BackgroundActions';
import { DonutsChart } from './DonutsChart';
import { ReturnedJobs } from './ReturnedJobs';

import * as S from './style';

export function DashboardMultiplesCharts(props: DashboardMultiplesChartsProps) {
  return (
    <S.Container>
      <S.Card>
        <ReturnedJobs />
      </S.Card>
      <S.Card className={'mid-grid'}>
        <BackgroundActions />
      </S.Card>
      <S.Card className={'larger-grid'}>
        <DonutsChart />
      </S.Card>
    </S.Container>
  );
}

interface DashboardMultiplesChartsProps {
  children?: ReactNode;
}
