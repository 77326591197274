/**
 * This file most to be created because some conflicts
 * between typescript with unsupported lib.
 *
 * There are 2 that kind:
 * WrapChart
 * WrapReactTooltip
 */
import React from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

const Wrap = ReactTooltip as any;

export function WrapReactTooltip(_props: WrapReactTooltipProps) {
  const { children, ...rest } = _props;

  return <Wrap {...rest}>{children}</Wrap>;
}

interface WrapReactTooltipProps extends TooltipProps {}
