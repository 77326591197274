import React, { ReactNode } from 'react';
import Chart from 'react-apexcharts';

import { LoadingCustom } from 'components/LoadingCustom';

import { Container } from './style';

const WrapChart = Chart as any;

// Source: https://codesandbox.io/s/jo3jq?file=/src/RealtimeLineChart.js:26-64
export function ListJobsChart(props: ListJobsChartProps) {
  const { loading, createdJobs, returnedJobs } = props;

  if (loading) {
    return (
      <Container style={{ display: 'flex' }}>
        <LoadingCustom medium />
      </Container>
    );
  }

  const series: ApexAxisChartSeries = [
    {
      name: 'Created Jobs',
      data: createdJobs?.map(job => ({ x: job.date, y: job.amount })),
      color: '#008efb',
    },
    {
      name: 'Returned Jobs',
      data: returnedJobs?.map(job => ({ x: job.returned_date, y: job.amount })),
      color: '#dcdc10',
    },
  ];

  return (
    <Container>
      <WrapChart
        height={400}
        type={'area'}
        options={{
          chart: {
            zoom: {
              enabled: false,
            },
            animations: {
              easing: 'linear',
              dynamicAnimation: {
                speed: 500,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            gradient: {
              stops: [0, 90],
            },
          },
          yaxis: {
            title: { text: 'Jobs' },
          },
        }}
        series={series}
      />
    </Container>
  );
}

interface ListJobsChartProps {
  children?: ReactNode;
  loading: boolean;
  createdJobs: {
    date: string;
    amount: number;
  }[];
  returnedJobs: {
    returned_date: string;
    amount: number;
  }[];
}
