/**
 * This file controls the table like:
 * # configuration
 * # column names
 * # sorting
 * # pagination
 *
 * Extra feature: copy content
 *
 * The data to feed the table is received by props
 */
import { useCallback, useEffect, useRef } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { useTheme } from 'styled-components';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import DataTable, { TableColumn } from 'react-data-table-component';

import { WrapReactTooltip } from 'components/WrapReactTooltip';
import { useJobSearch } from 'contexts/JobSearch';
import { JobsEdges } from 'contexts/JobSearch/types';
import { LoadingCustom } from 'components/LoadingCustom';
import { copyContent } from 'utils';

import { FormatedCell } from './FormatdCell';

import * as S from './style';

/* eslint react-hooks/exhaustive-deps: "off" */
export function TableJob(props: TableJobProps) {
  const { loading, jobs, hasMore, handleRequest, handleCleanList } =
    useJobSearch();

  const theme = useTheme();

  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (el: HTMLDivElement) => {
      if (loading) return;

      if (!hasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          console.log('### entry.isIntersecting', entry.isIntersecting);
          handleRequest();
        }
      });

      if (el) observer.current.observe(el);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    return () => {
      handleCleanList();
    };
  }, []);

  // Create columns config as required by react-data-table-component lib
  const columns: TableColumn<JobsEdges>[] = [
    {
      // Columns Name
      name: 'Job ID',
      // What information the table will work
      selector: ({ node }) => node.jobId,
      // That column is sortable
      sortable: true,
      // Format how the column items will be shown, could return a JSX or a string
      format: ({ node }) => {
        return <FormatedCell maxLength={15} content={node.jobId} />;
      },
    },
    {
      name: 'Job Name',
      selector: ({ node }) => node.jobName,
      sortable: true,
      format: ({ node }) => {
        return <FormatedCell maxLength={15} content={node.jobName} />;
      },
    },
    {
      name: 'External Id',
      selector: ({ node }) => node.externalId || '',
      sortable: true,
      format: ({ node }) => {
        return (
          <FormatedCell maxLength={15} content={String(node.externalId)} />
        );
      },
    },
    {
      name: 'Source Language',
      selector: ({ node }) => node.sourceLanguage,
      sortable: true,
      format: ({ node }) => {
        return (
          <FormatedCell maxLength={15} content={node.sourceLanguage || ''} />
        );
      },
    },
    {
      name: 'State',
      selector: ({ node }) => node.state,
      sortable: true,
      format: ({ node }) => {
        return <FormatedCell maxLength={15} content={node.state} />;
      },
    },
  ];

  let globalCount = 1;

  /**
   * Create a structure of string for the be copied
   */
  function handleCopyContent() {
    // Create rows as string[]
    const _data =
      jobs?.map(({ node }) => {
        let values: string[] = [];

        values.push(String(globalCount++));
        values.push(node.jobId);
        values.push(node.jobName);
        values.push(node.externalId || '');
        values.push(node.sourceLanguage || '');
        values.push(node.state);

        return values;
      }) || [];

    // Create headers as string[]
    const _titles = [
      '#',
      'Job ID',
      'Job Name',
      'External ID',
      'Source Language',
      'State',
    ];

    // Transfer content to Ctrl + C
    copyContent(_data, _titles);

    toast.success('Content copied');
  }

  const ODD = 1;
  const EVEN = 0;
  const _replace = 'arrayconnection:';

  const conditionalRowStyles = [
    {
      when: ({ cursor, node }: JobsEdges) => {
        const _index = Number(atob(cursor).replaceAll(_replace, ''));
        return _index % 2 === EVEN && !node.originalPriority;
      },
      style: (row: JobsEdges) => ({
        backgroundColor: 'lightpink',
      }),
    },
    {
      when: ({ cursor, node }: JobsEdges) => {
        const _index = Number(atob(cursor).replaceAll(_replace, ''));
        return _index % 2 === ODD && !node.originalPriority;
      },
      style: (row: JobsEdges) => ({
        backgroundColor: 'pink',
      }),
    },
  ];

  const InnerTitle = () => {
    return (
      <S.Title>
        <h3 className={'table-title-info'}>
          Job Search Return
          <BsInfoCircle
            size={18}
            color={theme.color.secondary}
            data-tip
            data-for={'title-info'}
          />
          <WrapReactTooltip id={'title-info'} type={'info'}>
            <div>
              The rows below shown in a 'pink color' are Stuck Jobs (Limbo)
            </div>
          </WrapReactTooltip>
        </h3>
        <div>
          {!loading && (
            <Button
              variant={'outlined'}
              size={'small'}
              style={{ marginLeft: 5 }}
              onClick={handleCopyContent}
            >
              Copy
            </Button>
          )}
        </div>
      </S.Title>
    );
  };

  return (
    <S.Container>
      <DataTable
        title={<InnerTitle />}
        columns={columns}
        data={jobs || []}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        conditionalRowStyles={conditionalRowStyles}
      />
      <div ref={lastElementRef}>
        {loading && <LoadingCustom medium style={{ height: 100 }} />}
      </div>
    </S.Container>
  );
}

/**
 * It is used on 'DataTable' as props 'expandableRowsComponent'
 *
 * It keep itself collapsable/hidden
 *
 * Clicking on the icon, expand to new Component.
 * That components just show the 'Raw data' which is used on the row
 */
const ExpandedComponent = ({ data }: { data: JobsEdges }) => (
  <pre>{JSON.stringify(data.node, null, 2)}</pre>
);

interface TableJobProps {
  loading: boolean;
  hasMore: boolean;
  jobs: JobsEdges[];
}
