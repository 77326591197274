import { css } from 'styled-components';

export const cssTool = {
  boxShadow: css`
    box-shadow: #33333311 1px 1px 4px;
  `,

  borderRadius: css`
    border: ${props => props.theme.border['light-gray']};
    border-radius: ${props => props.theme.border.radius};
  `,
};
