// WIP
import { ReactNode } from 'react';

import * as S from './style';

// source: https://codepen.io/Navedkhan012/pen/vrWQMY
// source: https://www.yeahhub.com/top-50-404-codepen-error-pages-free-download/
export function Error(_props: ErrorProps) {
  // const {} = _props;

  return (
    <S.Container>
      <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center">404</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">Look like you're lost</h3>

                  <p>the page you are looking for not avaible!</p>

                  <a href="/login" className="link_404">
                    Go to Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </S.Container>
  );
}

interface ErrorProps {
  children?: ReactNode;
}
