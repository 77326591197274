/**
 * File shows Donuts Chart
 *
 * Contains:
 * # Handle Job Per State Jobs Request
 * # Convert Response Request to suits on Chart
 * # Handle SubState Request / Switch charts and load its
 * # Copy Content Feature
 * # Handle condition to show differents Chart, by screen resolution
 */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ApexOptions } from 'apexcharts';
import { Button } from '@mui/material';

import { useDashboard } from 'contexts/Dashboard';
import { WrapApexCharts } from 'components/WrapChart';
import { LoadingCustom } from 'components/LoadingCustom';

import { JobPerStateItem, ListJobPerState } from './ListJobPerState';

import { ContainerDonuts } from './style';
import { copyContent } from 'utils';

/* eslint react-hooks/exhaustive-deps: "off" */
export function DonutsChart(_props: DonutsChartProps) {
  const componentMounted = useRef(true);

  const [loading, setLoading] = useState(false);

  const [subState, setSubState] = useState('');

  const [donutState, setDonutState] =
    useState<{ state: string; amount: number }[]>();

  const [donutSubState, setDonutSubState] =
    useState<{ string_status: string; amount: number }[]>();

  const { activeJobsPerStateRequest, activeJobsPerSubStateRequest } =
    useDashboard();

  // Source: https://stackoverflow.com/a/66891949/10859472
  useEffect(() => {
    fetchingJobsStates();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  async function fetchingJobsStates() {
    setLoading(true);

    try {
      const response = await activeJobsPerStateRequest();

      if (componentMounted.current) {
        setLoading(false);
        setDonutState(response.data.activeJobsPerState.data);
      }
    } catch (e) {
      setLoading(false);
      toast.error((e as any).message);
    }
  }

  async function fetchingJobsStatus(state: string) {
    setLoading(true);

    if (!subState) {
      setSubState(state);
    }

    try {
      const response = await activeJobsPerSubStateRequest(state);

      if (componentMounted.current) {
        setLoading(false);
        setDonutSubState(response.data.activeJobsPerStatus.data);
      }
    } catch (e) {
      setLoading(false);
      toast.error((e as any).message);
    }
  }

  async function handleRowClick(row: JobItem) {
    fetchingJobsStatus(row.state);
  }

  let total = 0;

  if (donutState) {
    if (subState && donutSubState) {
      total = donutSubState.reduce((acc, cur) => acc + cur.amount, 0);
    } else {
      total = donutState.reduce((acc, cur) => acc + cur.amount, 0);
    }
  }

  let data: DataProps[];

  if (subState && donutSubState) {
    data = donutSubState?.map((e, i) => ({
      ...e,
      id: i + 1,
      percentage: Number(parseFloat(`${(e.amount / total) * 100}`).toFixed(1)),
      state: e.string_status,
    }));
  } else {
    data =
      donutState?.map((e, i) => ({
        ...e,
        id: i + 1,
        percentage: Number(
          parseFloat(`${(e.amount / total) * 100}`).toFixed(1),
        ),
        string_status: '',
      })) || [];
  }

  data = data.sort((a, b) => {
    if (a.amount < b.amount) return 1;
    if (a.amount > b.amount) return -1;
    return 0;
  });

  const config: ApexOptions = {
    labels: data ? data.map(e => e.state) : [],
    title: {
      text: 'View Representation',
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238',
      },
    },
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: true,
    },
  };

  async function handleRowStateClick(job: JobPerStateItem) {
    if (!subState) {
      await handleRowClick(job);
    } else {
      toast.info('There is not a sub status job');
    }
  }

  async function handleRefreshButton() {
    if (subState) {
      fetchingJobsStatus(subState);
    } else {
      fetchingJobsStates();
    }
  }

  async function handleGoBackButton() {
    setSubState('');
    fetchingJobsStates();
  }

  function handleClickCopyContent(subState: string, data: DataProps[]) {
    const _data = subState
      ? data.map(({ id, state, amount, percentage }) => [
          id,
          state,
          amount,
          percentage + '%',
        ])
      : data.map(({ id, state, amount, percentage }) => [
          id,
          state,
          amount,
          percentage + '%',
        ]);

    copyContent(_data, ['ID', 'State', 'Amount', '%']);

    toast.success('Content copied');
  }

  return (
    <ContainerDonuts>
      <div className={'title-row'}>
        <h2>Job Per {!subState ? 'State' : 'SubState - ' + subState}</h2>
        <div>
          {subState && (
            <Button
              variant={'outlined'}
              size={'small'}
              onClick={handleGoBackButton}
            >
              Go back
            </Button>
          )}
          {!loading && (
            <Button
              variant={'outlined'}
              size={'small'}
              style={{ marginLeft: 5 }}
              onClick={() => handleClickCopyContent(subState, data)}
            >
              Copy
            </Button>
          )}
          {!loading && (
            <Button
              variant={'contained'}
              size={'small'}
              style={{ marginLeft: 5 }}
              onClick={handleRefreshButton}
            >
              Refresh
            </Button>
          )}
        </div>
      </div>
      <div className={'donuts-body'}>
        <div style={{ height: 360, overflowY: 'auto' }}>
          <ListJobPerState
            loading={loading}
            data={data}
            onRowClick={handleRowStateClick}
          />
        </div>
        <InnerDonutsChart config={config} donutState={data} loading={loading} />
      </div>
    </ContainerDonuts>
  );
}

interface DonutsChartProps {
  children?: ReactNode;
}

const InnerDonutsChart = ({ loading, config, donutState }: any) => {
  if (loading) {
    return (
      <div className={'loading-padding'}>
        <LoadingCustom medium />
      </div>
    );
  }

  return (
    <>
      <div className={'small-donuts'}>
        <WrapApexCharts
          options={config}
          series={donutState ? donutState.map((e: any) => e.amount) : []}
          type={'donut'}
          width={'520'}
        />
      </div>
      <div className={'big-donuts'}>
        <WrapApexCharts
          options={config}
          series={donutState ? donutState.map((e: any) => e.amount) : []}
          type={'donut'}
          width={'560'}
        />
      </div>
    </>
  );
};

type JobItem = {
  id: number;
  state: string;
  amount: number;
  percentage: number;
};

type DataProps = {
  id: number;
  percentage: number;
  amount: number;
  state: string;
  string_status: string;
};
