export const POST_VERIFY_TOKEN = (token: string) => ({
  query: `
    mutation ($token: String!) {
      verifyToken (token:$token) {
        payload
      }
    }
  `,
  variables: `{
    "token": "${token}"
  }`,
});
