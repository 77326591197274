import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { Button, TextField } from '@mui/material';

import { useLogin } from 'contexts/Login';
import { YupLogin } from 'services/yup/YupLogin';
import { LupaLogo } from 'components/LupaLogo';
import { LoadingCustom } from 'components/LoadingCustom';

import * as S from './style';

export function FormLogin(_props: FormLoginProps) {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { loginRequest } = useLogin();

  const { initialValues, validationSchema } = YupLogin.formLogin;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      setLoading(true);

      try {
        const response = await loginRequest({
          user: values.login,
          pass: values.password,
        });

        if (response?.data.tokenAuth?.user?.isNew) {
          navigate('/login/change-password');
        } else if (response?.data.tokenAuth) {
          navigate('/dashboard/welcome');
        } else {
          toast.warn('User or password was not found');
        }
      } catch (e) {
        toast.error((e as any).message);
      }

      setLoading(false);
    },
  });

  const { errors } = formik;

  return (
    <S.Container>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <LupaLogo />
        </div>

        <div className={'welcome-message'}>
          <strong>
            A clever person solves a problem
            <br />
            A wise person avoids it
            <br />
            Welcome to LUPA!
          </strong>
        </div>

        <div>
          <TextField
            id="lupa-login"
            name={'login'}
            type={'email'}
            label="Login"
            error={!!errors?.login}
            helperText={errors?.login}
            placeholder={'Type your e-mail'}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.login}
          />
        </div>

        <div>
          <TextField
            id="lupa-password"
            name={'password'}
            type={'password'}
            label="Password"
            error={!!errors?.password}
            helperText={errors?.password}
            placeholder={'Type your password'}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </div>

        <div>
          <InnerSubmitButton loading={loading} />
        </div>
      </form>
    </S.Container>
  );
}

interface FormLoginProps {
  children?: ReactNode;
}

const InnerSubmitButton = ({ loading }: { loading: boolean }) => {
  if (loading) return <LoadingCustom style={{ margin: '1rem 0' }} small />;

  return (
    <Button variant="contained" type={'submit'} fullWidth>
      Login
    </Button>
  );
};
