import lz from 'lz-string';

/**
 * Convert String to Encrypted Content
 */
const stash = str => {
  const step_one = lz.compress(str);
  const step_two = lz.compressToBase64(step_one);
  return step_two;
};

/**
 * Convert Encrypted Content to String
 */
const unstash = str => {
  const step_two = lz.decompressFromBase64(str);
  const step_one = lz.decompress(step_two);
  return step_one;
};

/**
 * Store an item, as localStorage.setItem function
 */
const setItem = (key, value) => {
  const crypted_value = stash(value);
  localStorage.setItem(key, crypted_value);
};

/**
 * Recovery an item, as localStorage.getItem function
 */
const getItem = key => {
  const crypted_value = localStorage.getItem(key);
  const value = unstash(crypted_value);
  return value;
};

/**
 * Clean storage, as localStorage.clear function
 */
const clear = () => localStorage.clear();

/**
 * Remove an item, as localStorage.removeItem function
 */
const removeItem = key => localStorage.removeItem(key);

/**
 * Get length from localStorage, as localStorage.length property
 */
const getLength = () => localStorage.length;

/**
 * Return the name of key, as localStorage.key function
 */
const key = index => localStorage.key(index);

/**
 * Return the all name of key (Exclusive Function)
 *
 * SOURCE: https://stackoverflow.com/questions/8419354/get-html5-localstorage-keys
 */
const getAllKeys = () => {
  const result = [];

  for (let i = 0, len = localStorage.length; i < len; ++i) {
    result.push(localStorage.key(i));
  }

  return result;
};

export const localStash = {
  getItem,
  setItem,
  removeItem,
  getAllKeys,
  key,
  getLength,
  clear,
  stash,
  unstash,
};
