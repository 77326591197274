import { ReactNode } from 'react';

import { useSideBar } from 'contexts/SideBar';
import { SideBarMenu } from 'components/SideBarMenu';
import { TopNavigation } from 'components/TopNavigation';

import * as S from './style';

export function DashboardTemplate({ children }: DashboardPropsTemplate) {
  const { isOpened } = useSideBar();

  return (
    <S.Container sideBarIsOpen={isOpened}>
      <div className={'sidebar'}>
        <SideBarMenu />
      </div>
      <main className={isOpened ? 'extended' : ''}>
        <TopNavigation />
        <div>{children}</div>
      </main>
    </S.Container>
  );
}

interface DashboardPropsTemplate {
  children?: ReactNode;
}
