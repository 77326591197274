import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { VscThreeBars, VscClose } from 'react-icons/vsc';
import { BiSearch } from 'react-icons/bi';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import * as Yup from 'yup';

import { useWindowSize } from 'hooks/useWindowSize';
import { useSideBar } from 'contexts/SideBar';

import { UserMenu } from './UserMenu';

import * as S from './style';

/* eslint-disable react-hooks/exhaustive-deps */
export function TopNavigation(props: TopNavigationProps) {
  const { isOpened, toogleSideBar } = useSideBar();
  const { color } = useTheme();

  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width < 600) {
      isOpened && toogleSideBar();
    }
  }, [isOpened, width]);

  const initialValues = {
    externalId: '',
  };

  const validationSchema = Yup.object({
    externalId: Yup.string().lowercase().trim().min(3),
  });

  const [, page, subpage] = location.pathname.split('/');

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async form => {
      const params = new URLSearchParams({
        externalId: form.externalId,
      });

      navigate(`/dashboard/job?${params}`, { replace: true });
    },
  });

  function handleIconButton() {
    toogleSideBar();
  }

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <S.Container>
      <div className={'title-top-side'}>
        <div className={'title-card'}>
          <span onClick={handleIconButton}>
            <HandleIconSidebar />
          </span>
        </div>

        <div>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id={'externalId'}
              name={'externalId'}
              placeholder={'Search by External ID'}
              error={!!formik.errors?.externalId}
              helperText={formik.errors?.externalId}
              InputProps={{
                endAdornment: (
                  <BiSearch
                    style={{ marginLeft: 5, cursor: 'pointer' }}
                    size={24}
                    color={color['input-icon']}
                    onClick={() => formik.submitForm()}
                  />
                ),
              }}
              onChange={formik.handleChange}
              value={formik.values.externalId}
            />
          </form>
        </div>

        <div className={'user-menu-side'}>
          <UserMenu />
        </div>
      </div>

      <div className={'title-bottom-side'}>
        <span onClick={handleGoBack}>{`< ${subpage}`}</span>
        <span>{`${page} / ${subpage}`}</span>
      </div>
    </S.Container>
  );
}

const HandleIconSidebar = () => {
  const { isOpened } = useSideBar();
  const { color } = useTheme();

  if (!isOpened) return <VscThreeBars color={color.secondary} size={22} />;

  return <VscClose color={color.secondary} size={26} />;
};

interface TopNavigationProps {
  children?: React.ReactNode;
}
