import styled from 'styled-components';

export const Container = styled.div`
  padding: ${props => props.theme.spacing.padding.small};

  .welcome-message {
    word-wrap: break-word;

    strong {
      color: ${props => props.theme.textColor['card-title']};
      text-align: center;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    min-width: 20rem;
    max-width: 23.5rem;
    justify-content: center;

    & > div {
      display: flex;
      justify-content: center;
      padding: 0.5rem;

      margin-bottom: 0.5rem;
    }

    & > .btn-group {
      display: flex;
      flex: 1;

      & > :first-child {
        margin-right: 5px;
      }
    }
  }
`;
