import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { Button, TextField } from '@mui/material';

import { useLogin } from 'contexts/Login';
import { YupLogin } from 'services/yup/YupLogin';
import { LupaLogo } from 'components/LupaLogo';
import { LoadingCustom } from 'components/LoadingCustom';

import * as S from './style';

// Source: https://formik.org/docs/api/formik
// Source: https://formik.org/docs/tutorial#schema-validation-with-yup
export function ChangePassword(_props: ChangePasswordProps) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { changePasswordRequest } = useLogin();

  const { initialValues, validationSchema } = YupLogin.formChangePassword;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async form => {
      setLoading(true);

      try {
        const response = await changePasswordRequest({ ...form });

        if (response.data.changeUserPassword.success) {
          toast.success('Success! Use the new password on your next access');
          navigate('/dashboard/welcome');
        } else {
          toast.warn('Occurred a problem during the request');
        }

        console.log('response', response);
      } catch (e) {
        toast.error((e as any).message);
      }

      setLoading(false);
    },
  });

  return (
    <S.Container>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <LupaLogo />
        </div>

        <div className={'welcome-message'}>
          <strong>
            For your security, the currently password is temporary.
            <br />
            Please, create a new password
          </strong>
        </div>

        <div>
          <TextField
            id="old-password"
            type={'password'}
            name={'oldPassword'}
            label={'Old Password'}
            error={!!formik.errors?.oldPassword}
            helperText={formik.errors?.oldPassword}
            placeholder={'Type your previous password'}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.oldPassword}
          />
        </div>

        <div style={{ marginBottom: 0 }}>
          <TextField
            id="new-password"
            type={'password'}
            name={'newPassword'}
            label={'New Password'}
            error={!!formik.errors?.newPassword}
            helperText={formik.errors?.newPassword}
            placeholder={'Type your new password'}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.newPassword}
          />
        </div>

        <div>
          <TextField
            id="repeat-new-password"
            type={'password'}
            name={'confirmNewPassword'}
            label={'Confirm your New Password'}
            error={!!formik.errors?.confirmNewPassword}
            helperText={formik.errors?.confirmNewPassword}
            placeholder={'Confirm'}
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.confirmNewPassword}
          />
        </div>

        <div className={'btn-group'}>
          <InnerGroupButton loading={loading} />
        </div>
      </form>
    </S.Container>
  );
}

interface ChangePasswordProps {
  children?: ReactNode;
}

const InnerGroupButton = ({ loading }: any) => {
  const navigate = useNavigate();

  if (loading) return <LoadingCustom style={{ margin: '1rem 0' }} small />;

  return (
    <>
      <Button
        variant="outlined"
        type={'button'}
        fullWidth
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Button variant="contained" type={'submit'} fullWidth>
        Done
      </Button>
    </>
  );
};
