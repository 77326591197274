import React, { ReactNode } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Container } from './style';

export function CopyContent(props: CopyContentProps) {
  const { children, content, ...rest } = props;

  return (
    <Container {...rest}>
      <>{children}</>
      <MdContentCopy
        size={22}
        color={'#aaa'}
        onClick={() => {
          toast.success('Content copied');
          navigator.clipboard.writeText(content);
        }}
      />
    </Container>
  );
}

interface CopyContentProps {
  children?: ReactNode;
  content: string;
}
