// WIP
import styled, { keyframes } from 'styled-components';
import {
  menuSelector,
  menuItemSelector,
  menuDividerSelector,
} from '@szhsin/react-menu/style-utils';

import { Menu as MenuInner } from '@szhsin/react-menu';

export const Container = styled.div``;

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;
const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

export const Menu = styled(MenuInner)`
  ${menuSelector.name} {
    font-size: ${props => props.theme.fontSize.small};
    user-select: none;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 6px;
    min-width: 10rem;

    top: 0px !important;
    left: -104.5px !important;
  }

  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.2s ease-out forwards;
  }

  ${menuItemSelector.name} {
    border-radius: 6px;
    padding: 0.375rem 0.625rem;
  }

  ${menuItemSelector.hover} {
    color: #fff;
    background-color: #59a2ff;
  }

  ${menuDividerSelector.name} {
    margin: 0.5rem 0.625rem;
  }

  ${menuItemSelector.submenu} {
    position: relative;
    &::after {
      content: '>';
      position: absolute;
      width: 7px;
      right: 0.625rem;
    }
  }
`;
