export const POST_INFLIGHT_INFO = () => ({
  query: `
    query {
      inflightInfo {
        maxJobs
        maxMinutes
      }
    }
  `,
});

export const POST_ACTIVE_JOBS_PER_STATE = () => ({
  query: `
    query {
      activeJobsPerState {
        data
      }
    }
  `,
});

export const POST_ACTIVE_JOBS_PER_SUB_STATE = (substate: string) => ({
  query: `
    query ($state:String!) {
      activeJobsPerStatus (state:$state) {
        data
      }
    }
  `,
  variables: `{
    "state": "${substate}"
  }`,
});

export const POST_RETURNED_JOBS_TIMELINE = (days: number) => ({
  query: `
    query returnedJobsTimeline($days: Int) {
      returnedJobsTimeline(days: $days) {
        data
      }
    }
  `,
  variables: `{
    "days": "${days}"
  }`,
});

export const POST_CREATED_JOBS_TIMELINE = (days: number) => ({
  query: `
    query ($days: Int) {
      createdJobsTimeline(days: $days) {
        data
      }
    }
  `,
  variables: `{
    "days": "${days}"
  }`,
});

export const POST_LATE_JOBS = () => ({
  query: `
    query {
      lateJobs {
        data
      }
    }
  `,
});

export const POST_BACKGROUND_ACTIONS_TIMELINE = (hours: number) => ({
  query: `
    query {
      backgroundActionsTimeline {
        data
      }
    }
  `,
  variables: `{
    "hours": "${hours}"
  }`,
});
