import styled from 'styled-components';

import { StyledWhiteCard } from 'components/StyledWhiteCard';

export const Container = styled(StyledWhiteCard)`
  display: flex;
  flex-direction: column;

  min-width: 20rem;
  padding: ${props =>
    props.theme.spacing.padding.big + ' ' + props.theme.spacing.padding.huge};

  @media only screen and (min-width: 1200px) {
    min-width: 420px;
    padding: ${props =>
      props.theme.spacing.padding.medium +
      ' ' +
      props.theme.spacing.padding.big};
  }

  .loading-box {
    display: flex;

    height: 550px;
    justify-content: center;
    align-items: center;
  }

  .late-job-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: ${props => props.theme.spacing.margin.medium};
  }

  /* .dashboard-column {
    gap: 2rem;
  } */
`;
