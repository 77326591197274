/**
 * This file contains the form to do a advanced search
 *
 * Contains:
 * # Load and Create get url params
 * # Form Initial Values nad Validation
 * # Form Fields
 * # Form Submit
 *
 * Route: /dashboard/job
 */
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { Button, Collapse, TextField, Checkbox } from '@mui/material';
import { useSearchParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import CreatableSelect from 'react-select/creatable';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import * as Yup from 'yup';

import { RELATIVES_DATE_MAP } from 'utils/constants';
import { useJobSearch } from 'contexts/JobSearch';
import { LoadingCustom } from 'components/LoadingCustom';
import { StyledWhiteCard } from 'components/StyledWhiteCard';
import { JobAdvancedSearchForm, SelectProp } from 'contexts/JobSearch/types';

import { TextFieldCustom } from './TextFieldCustom';
import { TableJob } from './TableJob';

import * as S from './style';

/* eslint react-hooks/exhaustive-deps: "off" */
export function Job(_props: JobProps) {
  const componentMounted = useRef(true);

  const [, setUrlParams] = useSearchParams();

  const location = useLocation();

  const initialValues: JobAdvancedSearchForm = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);

    const paramRelativeDate = urlParams.get('relativeDateType');
    const relativeDateType = {
      value: paramRelativeDate || RELATIVES_DATE_MAP.MINUTES,
      label: paramRelativeDate || RELATIVES_DATE_MAP.MINUTES,
    };

    /**
     * This is the initializing data to the formulary
     */
    return {
      // Loads values from URL params or create as default values
      jobId: urlParams.get('jobId') || '',
      externalId: urlParams.get('externalId') || '',
      jobName: urlParams.get('jobName') || '',

      states: [] as string[],
      deleted: urlParams.get('deleted') === 'true' || false,

      created_Lte: urlParams.get('created_Lte') || '',
      created_Gte: urlParams.get('created_Gte') || '',

      statusLastUpdate_Lte: urlParams.get('statusLastUpdate_Lte') || '',
      statusLastUpdate_Gte: urlParams.get('statusLastUpdate_Gte') || '',

      relativeDate: 0,
      relativeDateType: relativeDateType,

      state_In: handleSelectProps(urlParams.get('state_In')),
      state_NotIn: handleSelectProps(urlParams.get('state_NotIn')),

      stringStatus_ContainsIn: handleSelectProps(
        urlParams.get('stringStatus_ContainsIn'),
      ),
      stringStatus_NotContainsIn: handleSelectProps(
        urlParams.get('stringStatus_NotContainsIn'),
      ),

      orderBy: '-created',
    };
  }, [location.search]);

  const jobSearch = useJobSearch();

  /**
   * Component did mount
   *
   * Its trigger when the component is mounted
   */
  useEffect(() => {
    jobSearch.handleSetCollapsable(false);

    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const { jobId, jobName, externalId } = initialValues;

    if (jobId || jobName || externalId) {
      handleAdvancedSearchRequest(initialValues);
    }
  }, [initialValues.externalId]);

  function convertSelectPropsToString(arr: SelectProp[]) {
    return arr?.map(({ value }) => value.trim()).join(',');
  }

  async function handleAdvancedSearchRequest(form: JobAdvancedSearchForm) {
    try {
      await jobSearch.handleCleanList();
      await jobSearch.handleRequest(form);
    } catch (e) {
      toast.warn((e as any).message);
    }
  }

  // Formik handles the entire formulary with: initialValues,
  // validationsSchema and onSubmit function
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      jobId: Yup.string(),
      externalId: Yup.string(),
      jobName: Yup.string(),
    }),
    onSubmit: async form => {
      await jobSearch.handleCleanList();

      // Convert SelectProps to String[]
      const urlParams = jobSearch.isCollapsable
        ? {
            ...form,

            relativeDateType: form.relativeDateType.value,

            state_In: convertSelectPropsToString(form.state_In),
            state_NotIn: convertSelectPropsToString(form.state_NotIn),

            stringStatus_ContainsIn: convertSelectPropsToString(
              form.stringStatus_ContainsIn,
            ),
            stringStatus_NotContainsIn: convertSelectPropsToString(
              form.stringStatus_NotContainsIn,
            ),
          }
        : {
            jobId: form.jobId,
            externalId: form.externalId,
            jobName: form.jobName,
          };

      setUrlParams(urlParams as any, { replace: true });

      const simpleForm = {
        jobId: form.jobId,
        externalId: form.externalId,
        jobName: form.jobName,
      } as any;

      await handleAdvancedSearchRequest(
        jobSearch.isCollapsable ? form : simpleForm,
      );
    },
  });

  // Reset part of formulary
  function handleClearRelativeDate() {
    formik.setFieldValue('relativeDate', 0);
    formik.setFieldValue('relativeDateType', {
      value: RELATIVES_DATE_MAP.MINUTES,
      label: RELATIVES_DATE_MAP.MINUTES,
    });
  }

  // Prepare a function to handle with a changeOfValue from Select Components
  function handleMultiSelectChange(field: keyof typeof initialValues) {
    return function handleChange(
      newValue: OnChangeValue<SelectOption, true>,
      actionMeta: ActionMeta<SelectOption>,
    ) {
      const optionsList = newValue as SelectProp[];

      if (actionMeta.action === 'create-option') {
        // If it is a new option
        const _new = optionsList?.find(val => val.__isNew__);

        // And if has comma
        if (_new?.value.includes(',')) {
          // Split and create new values
          const newOptionList = _new.value.split(',').map(val => ({
            label: val.trim(),
            value: val.trim(),
          }));

          // Remove the last one
          optionsList.pop();

          // Add new value (oldList + newList)
          formik.setFieldValue(field, newOptionList.concat(optionsList));
          return;
        }
      }

      // Just add as a single value
      formik.setFieldValue(field, newValue as SelectProp[]);
    };
  }

  return (
    <S.Container>
      <form onSubmit={formik.handleSubmit}>
        <StyledWhiteCard className={'input-row'}>
          <div className={'row top-grid'}>
            <TextField
              id="jobId"
              name={'jobId'}
              type={'text'}
              label={'Job Id'}
              placeholder={'Type the Job Id'}
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.jobId}
            />

            <TextField
              id="externalId"
              name={'externalId'}
              type={'text'}
              label={'External Id'}
              placeholder={'Type the External Id'}
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.externalId}
            />

            <TextField
              id="jobName"
              name={'jobName'}
              type={'text'}
              label={'Job Name'}
              placeholder={'Type the Job Name'}
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.jobName}
            />
          </div>

          <Button
            variant={'outlined'}
            onClick={() =>
              jobSearch.handleSetCollapsable(!jobSearch.isCollapsable)
            }
            fullWidth
          >
            Advanced Options
          </Button>

          <Collapse in={jobSearch.isCollapsable} timeout={'auto'} unmountOnExit>
            <div className={'advanced-row'}>
              <label>
                <h5>Jobs Created:</h5>
              </label>
            </div>

            <div className={'advanced-row'}>
              <div>
                <TextFieldCustom
                  label={'Created After'}
                  name={'created_Gte'}
                  formik={formik}
                  type={'datetime-local'}
                  size={'small'}
                  fullWidth
                />
              </div>

              <div>
                <label htmlFor="created_Lte">Created Before:</label>
                <TextField
                  id="created_Lte"
                  name={'created_Lte'}
                  type={'datetime-local'}
                  size={'small'}
                  fullWidth
                  value={formik.values.created_Lte}
                  onChange={formik.handleChange}
                  disabled={!!formik.values.relativeDate}
                  error={!!formik.errors.created_Lte}
                  helperText={formik.errors.created_Lte}
                />
              </div>
            </div>

            <div className={'advanced-row'}>
              <label>
                <h5>Jobs Created Relative:</h5>
              </label>
            </div>

            <div className={'advanced-row'}>
              <div>
                <label htmlFor="relativeDate">From now, until: </label>
                <TextField
                  id="relativeDate"
                  name={'relativeDate'}
                  type={'number'}
                  size={'small'}
                  fullWidth
                  placeholder={'Place the date unit'}
                  onChange={formik.handleChange}
                  value={formik.values.relativeDate}
                />
              </div>

              <div>
                <label htmlFor="relativeDate">Using unit: </label>
                <Select
                  id="relativeDateType"
                  name={'relativeDateType'}
                  options={defaultRelativeDateUnit}
                  value={formik.values.relativeDateType}
                  onChange={val => {
                    formik.setFieldValue('relativeDateType', val);
                  }}
                />

                <Button variant={'outlined'} onClick={handleClearRelativeDate}>
                  Clear Relative Date
                </Button>
              </div>
            </div>

            <div className={'advanced-row'}>
              <label>
                <h5>Delete: </h5>
              </label>
            </div>

            <div className={'advanced-row'}>
              <div>
                <label htmlFor="deleted">Is deleted?</label>
                <Checkbox
                  id={'deleted'}
                  name={'deleted'}
                  color={'info'}
                  value={formik.values.deleted}
                  checked={formik.values.deleted}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className={'advanced-row'}>
              <label>
                <h5>Last status Updated:</h5>
              </label>
            </div>

            <div className={'advanced-row'}>
              <div>
                <label htmlFor="statusLastUpdate_Gte">After: </label>
                <TextField
                  id="statusLastUpdate_Gte"
                  name={'statusLastUpdate_Gte'}
                  type={'datetime-local'}
                  size={'small'}
                  value={formik.values.statusLastUpdate_Gte}
                  onChange={formik.handleChange}
                />
              </div>

              <div>
                <label htmlFor="statusLastUpdate_Lte">Before: </label>
                <TextField
                  id="statusLastUpdate_Lte"
                  name={'statusLastUpdate_Lte'}
                  type={'datetime-local'}
                  size={'small'}
                  value={formik.values.statusLastUpdate_Lte}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className={'advanced-row'}>
              <label>
                <h5>state select: </h5>
              </label>
            </div>

            <div className="advanced-row">
              <div>
                <label htmlFor="state_In">Contains Status: </label>
                <CreatableSelect
                  id={'state_In'}
                  name={'state_In'}
                  placeholder={'Type your suggestion'}
                  isMulti
                  options={statusOptions}
                  styles={styleForMultiSelect}
                  value={formik.values.state_In}
                  onChange={handleMultiSelectChange('state_In')}
                />
              </div>

              <div>
                <label htmlFor="state_NotIn">Not Contains Status: </label>
                <CreatableSelect
                  id={'state_NotIn'}
                  name={'state_NotIn'}
                  placeholder={'Type your suggestion'}
                  isMulti
                  styles={styleForMultiSelect}
                  options={statusOptions}
                  value={formik.values.state_NotIn}
                  onChange={handleMultiSelectChange('state_NotIn')}
                />
              </div>
            </div>

            <div className={'advanced-row'}>
              <label>
                <h5>string status: </h5>
              </label>
            </div>

            <div className={'advanced-row'}>
              <div>
                <label htmlFor="stringStatus_ContainsIn">
                  Contains String:{' '}
                </label>
                <CreatableSelect
                  id={'stringStatus_ContainsIn'}
                  name={'stringStatus_ContainsIn'}
                  placeholder={'Type your suggestion'}
                  isMulti
                  options={[]}
                  styles={styleForMultiSelect}
                  value={formik.values.stringStatus_ContainsIn}
                  onChange={handleMultiSelectChange('stringStatus_ContainsIn')}
                />
              </div>

              <div>
                <label htmlFor="stringStatus_ContainsNotIn">
                  Not Contains String:{' '}
                </label>
                <CreatableSelect
                  id={'stringStatus_ContainsNotIn'}
                  name={'stringStatus_ContainsNotIn'}
                  placeholder={'Type your suggestion'}
                  isMulti
                  options={[]}
                  styles={styleForMultiSelect}
                  value={formik.values.stringStatus_NotContainsIn}
                  onChange={handleMultiSelectChange(
                    'stringStatus_NotContainsIn',
                  )}
                />
              </div>
            </div>
          </Collapse>

          <div
            className={'advanced-row'}
            style={{ justifyContent: 'flex-end' }}
          >
            {jobSearch.loading ? (
              <div className={'loading-row'}>
                <LoadingCustom medium style={{ height: 60 }} />
              </div>
            ) : (
              <Button variant={'contained'} onClick={formik.submitForm}>
                Search
              </Button>
            )}
          </div>
        </StyledWhiteCard>
      </form>
      <div style={{ marginTop: 15 }}>
        <TableJob
          loading={jobSearch.loading}
          jobs={jobSearch.jobs}
          hasMore={jobSearch.hasMore}
        />
      </div>
    </S.Container>
  );
}

// Pre existing options
const statusOptions = (
  'APPROVING, REVIEWING, QCREJECT, FINALIZING, FINALIZED, RETURNING_IWP, ' +
  'TRANSCRIBED, TRANSCRIBING, QCREVIEW, REVIEWED, RETURNED, CHUNKJOB'
)
  .split(',')
  .map((e, i) => {
    return {
      value: e,
      label: e,
    };
  });

// Object with Multiselect style. Reason: keep the JSX simple
const styleForMultiSelect = {
  control: (provided: any, state: any) => {
    return { ...provided, minWidth: 190 };
  },
};

interface JobProps {
  children?: ReactNode;
}

export interface SelectOption {
  readonly value: string;
  readonly label: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const defaultRelativeDateUnit = [
  {
    value: RELATIVES_DATE_MAP.MINUTES,
    label: RELATIVES_DATE_MAP.MINUTES,
  },
  {
    value: RELATIVES_DATE_MAP.HOURS,
    label: RELATIVES_DATE_MAP.HOURS,
  },
  {
    value: RELATIVES_DATE_MAP.DAYS,
    label: RELATIVES_DATE_MAP.DAYS,
  },
  {
    value: RELATIVES_DATE_MAP.MONTHS,
    label: RELATIVES_DATE_MAP.MONTHS,
  },
];

// Convert string from URL Param to SelectProps[].
// Using during initalizing values
function handleSelectProps(str?: string | null) {
  if (str)
    return str.split(',').map(str => ({
      label: str.trim(),
      value: str.trim(),
    }));

  return [];
}
