import styled from 'styled-components';
import { StyledWhiteCard } from 'components/StyledWhiteCard';

export const Container = styled(StyledWhiteCard)`
  display: flex;
  height: 9rem;

  flex: 1 1 400px;

  :hover {
    cursor: pointer;
  }

  & > div {
    display: flex;
    flex: 1;
  }

  .info-card {
    display: flex;
    flex: 1;
    flex-direction: column;

    & > * {
      display: flex;
      flex: 1;
      align-items: center;
    }

    strong {
      font-size: ${props => props.theme.fontSize.medium};
      color: ${props => props.theme.textColor.title};
      text-transform: uppercase;
    }

    /* h1,
    h2,
    h3,
    h4,
    h5 {
      color: ${props => props.theme.textColor.title};
      font-size: ${props => props.theme.fontSize.big};
      text-transform: uppercase;
    } */
  }

  .icon-card {
    display: flex;
    flex: 0.35;

    justify-content: flex-end;
    align-items: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 4.4rem;
      width: 4.4rem;
      border-radius: 50%;

      background-color: ${props => props.theme.background['card-icon']};
    }

    span.white {
      filter: brightness(1.1) !important;
      background-color: ${props =>
        props.theme.background['card-icon']} !important;
    }
  }
`;
