import styled from 'styled-components';

export const Container = styled.div`
  .small-chart {
    display: flex;

    align-items: center;
    justify-content: center;

    margin-left: -30px;
    margin-bottom: -30px;
  }

  .hover-title {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    min-width: 165px;
    margin-bottom: 5px;
  }

  .hover-list {
    list-style: none;

    li {
      display: flex;
      flex: 1;
      justify-content: space-around;
    }
  }

  [data-column-id='2'].rdt_TableCol,
  [data-column-id='2'].rdt_TableCell {
    display: flex;
    justify-content: center;
    max-width: 120px;
  }
`;
