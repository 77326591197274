import { useNavigate } from 'react-router-dom';
import {
  ClickEvent,
  MenuButton,
  MenuDivider,
  MenuItem,
} from '@szhsin/react-menu';

import '@szhsin/react-menu/dist/core.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { localStash } from 'services/stash';

import * as S from './style';

export function UserMenu() {
  const navigate = useNavigate();

  function handleExitButton(e: ClickEvent) {
    e.stopPropagation = true;
    e.keepOpen = false;
    localStash.clear();
    navigate('/login/welcome');
  }

  return (
    <S.Container>
      <S.Menu
        transition
        menuButton={<MenuButton></MenuButton>}
        onItemClick={handleExitButton}
      >
        <MenuDivider />
        <MenuItem onClick={handleExitButton}>Exit</MenuItem>
      </S.Menu>
    </S.Container>
  );
}
