import styled from 'styled-components';

export const Container = styled.div`
  .title-row {
    display: flex;
    justify-content: space-between;
  }

  .background-list {
    max-height: 420px;
    overflow-y: auto;
  }

  .loading-frame {
    display: flex;
    height: 360px;

    align-items: center;
    justify-content: center;
  }
`;
