/**
 * File shows Jobs per State Table, on Donuts Chart side
 *
 * Table config / columns configs / sorting / pagination
 */
import React, { ReactNode } from 'react';
import DataTable from 'react-data-table-component';

import { LoadingCustom } from 'components/LoadingCustom';

import * as S from './style';

// IMPORTANT SOURCE: https://react-data-table-component.netlify.app/?path=/docs/examples-export-csv--export-csv
export function ListJobPerState(props: ListJobPerStateProp) {
  const { loading, data, onRowClick } = props;

  return (
    <S.Container>
      <DataTable
        columns={columns}
        data={data || []}
        striped
        defaultSortFieldId={2}
        defaultSortAsc={false}
        progressPending={loading}
        progressComponent={<LoadingCustom medium style={{ height: 100 }} />}
        onRowClicked={(row, _event) => onRowClick(row)}
      />
    </S.Container>
  );
}

interface ListJobPerStateProp {
  children?: ReactNode;
  loading: boolean;
  data: JobPerStateItem[];
  onRowClick: (row: JobPerStateItem) => Promise<void>;
}

export type JobPerStateItem = {
  id: number;
  state: string;
  amount: number;
  percentage: number;
};

const columns = [
  {
    name: 'State',
    selector: (row: JobPerStateItem) => row.state,
    sortable: true,
  },
  {
    name: 'Amount',
    selector: (row: JobPerStateItem) => row.amount,
    sortable: true,
  },
  {
    name: '%',
    selector: (row: JobPerStateItem) => row.percentage,
    sortable: true,
    format: (row: JobPerStateItem) => `${row.percentage}%`,
  },
];
