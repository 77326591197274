import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { defaultTheme } from 'styles/theme';
import { GlobalStyle } from 'styles/global';

import { LoginProvider } from './Login';
import { SideBarProvider } from './SideBar';
import { DashboardProvider } from './Dashboard';
import { GraphQLHandlerProvider } from './GraphQLHandler';
import { JobSearchProvider } from './JobSearch';

export function GeneralContextProvider(props: GeneralContextProviderProps) {
  const { children } = props;

  return (
    <GraphQLHandlerProvider>
      <ThemeProvider theme={defaultTheme}>
        <LoginProvider>
          <SideBarProvider>
            <JobSearchProvider>
              <DashboardProvider>
                <GlobalStyle />
                {children}
              </DashboardProvider>
            </JobSearchProvider>
          </SideBarProvider>
        </LoginProvider>
      </ThemeProvider>
    </GraphQLHandlerProvider>
  );
}

interface GeneralContextProviderProps {
  children?: ReactNode;
}
