import * as Yup from 'yup';

export const YupLogin = {
  formLogin: {
    initialValues: {
      login: '',
      password: '',
    },

    validationSchema: Yup.object({
      login: Yup.string()
        .email()
        .lowercase()
        .trim()
        .min(3, () => {
          return 'Login must have more than 3 characters';
        })
        .max(40, () => {
          return 'Login must have less than 40 characters';
        })
        .required('This field is required'),
      password: Yup.string()
        .min(3)
        .max(40)
        .trim()
        .required('This field is required'),
    }),
  },

  formChangePassword: {
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },

    validationSchema: Yup.object({
      oldPassword: Yup.string().required('This field is required'),
      newPassword: Yup.string().required('This field is required'),
      confirmNewPassword: Yup.string()
        .min(3)
        .max(25)
        .trim()
        .required('This field is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    }),
  },
};
