import { ReactNode } from 'react';

import { DashboardLateJobs } from './DashboardLateJobs';
import { DashboardMultiplesCharts } from './DashboardMultiplesCharts';
import { DashboardPainel } from './DashboardPainel';

import * as S from './style';

export function Main(_props: MainProps) {
  return (
    <S.Container>
      <DashboardPainel />

      <section>
        <div className={'dashboard-body margin-body'}>
          <DashboardMultiplesCharts />
        </div>

        <DashboardLateJobs />
      </section>
    </S.Container>
  );
}

interface MainProps {
  children?: ReactNode;
}
