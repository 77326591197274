import { createContext, ReactNode, useContext, useState } from 'react';

interface SideBar {
  isOpened: boolean;
  toogleSideBar: () => void;
}

const SideBarContext = createContext<SideBar>({} as SideBar);

export function SideBarProvider({ children }: SideBarProviderProps) {
  const [isOpened, setIsOpened] = useState(true);

  function toogleSideBar() {
    setIsOpened(oldIsOpened => !oldIsOpened);
  }

  return (
    <SideBarContext.Provider value={{ isOpened, toogleSideBar } as SideBar}>
      {children}
    </SideBarContext.Provider>
  );
}

interface SideBarProviderProps {
  children?: ReactNode;
}

export function useSideBar() {
  const context = useContext(SideBarContext);

  return context;
}
