import { ReactNode, useContext, createContext } from 'react';

import { useGraphQLHandler } from 'contexts/GraphQLHandler';
import { api } from 'services/api';
import { localStash } from 'services/stash';

import {
  ChangePasswordForm,
  ChangePasswordResponse,
  LoginForm,
  LoginResponse,
} from './types';

import * as gql from './queries';

interface Login {
  loginRequest: (form: LoginForm) => Promise<LoginResponse>;
  changePasswordRequest: (
    form: ChangePasswordForm,
  ) => Promise<ChangePasswordResponse>;
}

const LoginContext = createContext<Login>({} as Login);

export function LoginProvider({ children }: LoginProviderProps) {
  const { graphqlRequest } = useGraphQLHandler();

  async function loginRequest(form: LoginForm) {
    // Every loginRequest, it clean the localStorage
    localStash.clear();

    // Add a default prop to every api request
    api.defaults.headers.common = { 'Content-Type': 'application/json' };

    // Create the login query
    const body = gql.POST_LOGIN(form);
    const response = await graphqlRequest<LoginResponse>(body);

    // Add the JWT token as Api Header Prop
    const token = response.data.tokenAuth?.token;
    api.defaults.headers.common = { Authorization: `JWT ${token}` };

    // Set the token on the localStorage
    localStash.setItem('@token', token);

    return response;
  }

  async function changePasswordRequest(form: ChangePasswordForm) {
    // Create the ChangePassword query
    const body = gql.POST_CHANGE_PASSWORD(form);
    const response = await graphqlRequest<ChangePasswordResponse>(body);

    // Add the JWT token as Api Header Prop
    const token = response.data.changeUserPassword.token;
    api.defaults.headers.common = { Authorization: `JWT ${token}` };

    // Set the token on the localStorage
    localStash.setItem('@token', token);

    return response;
  }

  return (
    <LoginContext.Provider value={{ loginRequest, changePasswordRequest }}>
      {children}
    </LoginContext.Provider>
  );
}

interface LoginProviderProps {
  children?: ReactNode;
}

export function useLogin() {
  const context = useContext(LoginContext);

  return context;
}
