// WIP
import styled from 'styled-components';
import { StyledWhiteCard } from 'components/StyledWhiteCard';

export const Card = styled(StyledWhiteCard)`
  display: flex;
  flex-direction: column;

  min-width: 20%;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;

  section {
    display: flex;

    @media only screen and (max-width: 1600px) {
      flex-direction: column;
    }
  }

  .dashboard-body {
    display: flex;
    flex: 1;
  }

  .dashboard-column {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    gap: ${props => props.theme.spacing['grid-padding'].small};

    @media only screen and (min-width: 1600px) {
      margin: 1rem 1rem 1.5rem 0.25rem;
      max-width: 350px;
    }

    @media only screen and (max-width: 1600px) {
      grid-template-columns: repeat(2, minmax(5rem, 1fr));
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(1, minmax(20rem, 1fr));
    }
  }
`;
