import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  'theme-name': 'Default',

  border: {
    radius: '0.25rem',
    'light-gray': '0.5px solid #eaeaea',
    'card-shadow': '#33333311 1px 1px 4px',
  },

  spacing: {
    padding: {
      small: '0.5rem',
      medium: '0.75rem',
      big: '1rem',
      huge: '2rem',
    },
    margin: {
      small: '0.5rem',
      medium: '0.75rem',
      big: '1rem',
      huge: '2rem',
    },
    'grid-padding': {
      small: '0.5rem',
      medium: '0.75rem',
      big: '1rem',
    },
  },

  color: {
    primary: '#F5A359',
    secondary: '#3e8bb7',

    bar: '#47629B',
    'input-icon': '#3e3e3e',
    'sidebar-icon': '#ffffff',
  },

  textColor: {
    title: '#9599AD',
    'sub-title': '#161616',
    'card-title': '#9599AD',
    'card-value': '#161616',
    'side-bar': '#ffffff',
    breadcrumb: '#A1A1A1',
    'table-header': '#9599AD',
    'table-cell': '#121212',
  },

  fontSize: {
    small: 'calc(1rem - 1px)',
    medium: '1rem',
    big: '2rem',
    huge: '2.5rem',
  },

  background: {
    overlay: 'rgba(0, 0, 0, 0.5)',
    'dashboard-body': '#E5E5E5',
    'top-navigation': '#ffffff',
    'side-navigation': '#3B84AE',
    'card-icon': '#F8F8F8',
    'card-body': '#ffffff',
  },

  gradient: {
    'login-background':
      'linear-gradient(180deg, rgba(24,161,195,1) 0%, rgba(1,124,157,1) 77%);',
  },
};

// export const defaultTheme: DefaultTheme = {
//   title: 'default',

// primaryColor: '#F5A359',
// secondaryColor: '#3e8bb7',
// backgroundGradientColor:
//   'linear-gradient(180deg, rgba(24,161,195,1) 0%, rgba(1,124,157,1) 77%);',

// backgroundOverlayColor: 'rgba(0, 0, 0, 0.5)',

// backgroundColor: '#F8F8F8',
// backgroundColorTopNavigation: '#FFFFFF',
// cardBorderColor: '#e9ebec',
// cardTitleColor: '#9599ad',
// cardValueColor: '#9599ad',
// cardBadgeBackgroundColorGreen: '#2cb57e',
// cardBadgeBackgroundColorOrage: '#f5bd58',
// cardBadgeBackgroundColorRed: '#f56e50',
// };

export const whiteTheme = defaultTheme;

// export const blackTheme: DefaultTheme = {
//   title: 'black',

// primaryColor: '#F5A359',
// secondaryColor: '#3e8bb7',
// backgroundGradientColor:
//   'linear-gradient(180deg, rgba(24,161,195,1) 0%, rgba(1,124,157,1) 77%);',

// backgroundOverlayColor: 'rgba(0, 0, 0, 0.5)',

// backgroundColor: '#333',
// backgroundColorTopNavigation: '#2c2c2c',
// cardBorderColor: '#3d3d3d',
// cardTitleColor: '#9599ad',
// cardValueColor: '#9599ad',
// cardBadgeBackgroundColorGreen: '#2cb57e',
// cardBadgeBackgroundColorOrage: '#f5bd58',
// cardBadgeBackgroundColorRed: '#f56e50',
// };
