import React, { ReactNode } from 'react';
import { ApexOptions } from 'apexcharts';
import { WrapApexCharts } from 'components/WrapChart';

export function SimpleChart(_props: SimpleChartProps) {
  const { rangeTitle, lateJobs } = _props;

  const config: ApexOptions = {
    labels: lateJobs.map(e => e.state),
    title: {
      text: rangeTitle,
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        color: '#263238',
      },
    },
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <WrapApexCharts
      type="bar"
      options={config}
      series={[
        {
          name: rangeTitle,
          data: lateJobs
            .filter(late => late.range === rangeTitle)
            .map(late => late.amount),
        },
      ]}
    />
  );
}

interface SimpleChartProps {
  children?: ReactNode;
  rangeTitle: string;
  lateJobs: {
    state: string;
    range: string;
    amount: number;
  }[];
}
