import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';

import { useDashboard } from 'contexts/Dashboard';

import { ListJobsChart } from './ListJobsChart';

import * as S from './style';

/* eslint react-hooks/exhaustive-deps: "off" */
export function ReturnedJobs(_props: ReturnedJobsComponentProps) {
  const componentMounted = useRef(true);

  const { createdJobsTimeline, returnedJobsTimeline } = useDashboard();

  const [lastDays, setLastDays] = useState(7);
  const [loading, setLoading] = useState(true);

  const [returnedJobs, setReturnedJobs] = useState<ReturnedJobsProps[]>([]);

  const [createdJobs, setCreatedJobs] = useState<CreatedJobsProps[]>([]);

  useEffect(() => {
    handleAllFetching(lastDays);

    return () => {
      componentMounted.current = false;
    };
  }, []);

  /**
   * Trigger the request by days
   * Ends the process if component is Unmonted
   */
  async function fetchingCreatedJobsTimeline(days = 30) {
    setLoading(true);

    try {
      const { data } = await createdJobsTimeline(days);

      if (componentMounted.current) {
        setCreatedJobs(data.createdJobsTimeline.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toast.error((e as any).message);
    }
  }

  /**
   * Trigger the request by days
   * Ends the process if component is Unmonted
   */
  async function fetchingReturnedJobs(days = 30) {
    setLoading(true);

    try {
      const { data } = await returnedJobsTimeline(days);

      if (componentMounted.current) {
        setReturnedJobs(data.returnedJobsTimeline.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toast.error((e as any).message);
    }
  }

  async function handleAllFetching(days = 30) {
    setLastDays(days);

    // Trigger 2 request simultaneous
    await Promise.all([
      fetchingCreatedJobsTimeline(days),
      fetchingReturnedJobs(days),
    ]);
  }

  return (
    <S.Container>
      <div className={'returned-jobs-title'}>
        <h2>NEW RETURNED JOBS ({lastDays} Days)</h2>
        <div>
          {!loading && (
            <Button
              variant={'contained'}
              size={'small'}
              onClick={() => handleAllFetching(7)}
            >
              Last 7 days
            </Button>
          )}

          {!loading && (
            <Button
              variant={'contained'}
              size={'small'}
              onClick={() => handleAllFetching(30)}
              style={{ marginLeft: 5 }}
            >
              Last 30 days
            </Button>
          )}
        </div>
      </div>
      <div>
        <ListJobsChart
          loading={loading}
          createdJobs={createdJobs}
          returnedJobs={returnedJobs}
        />
      </div>
    </S.Container>
  );
}

interface ReturnedJobsComponentProps {}

type ReturnedJobsProps = {
  returned_date: string;
  amount: number;
};

type CreatedJobsProps = {
  date: string;
  amount: number;
};
