import React, { ReactNode } from 'react';

import * as S from './style';

export function LoginTemplate(_props: LoginTemplateProps) {
  const { children } = _props;

  return (
    <S.Container>
      <div className="login-box">{children}</div>
    </S.Container>
  );
}

interface LoginTemplateProps {
  children?: ReactNode;
}
