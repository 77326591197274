/**
 * Receive 2 arrays
 *
 * The first one is a array of array
 * The second one is array of string
 *
 * The rows are joint by \t (tab), after this is joint by \n (newline)
 */
export const copyContent = (rows: any[], titles?: string[]) => {
  let title = titles ? titles.join('\t') + '\n' : '';

  let content = rows
    .map((_subarr: any[]) => {
      return _subarr.join('\t');
    })
    .join('\n');

  // Copy the string to Clipboard
  navigator.clipboard.writeText(title + content);
};
