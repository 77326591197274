import { useEffect } from 'react';

// SOURCE: https://medium.com/@kevinfelisilda/click-outside-element-event-using-react-hooks-2c540814b661
const useOutsideComponentClickHandle = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  return null;
};

export { useOutsideComponentClickHandle };
