import { JobAdvancedSearchForm } from './types';

type FieldsProps = keyof JobAdvancedSearchForm;

const handleNull = (form: JobAdvancedSearchForm, field: FieldsProps) => {
  if (!!form[field]) {
    if ((form[field] as string).includes('[')) return `${form[field]}`;
    if ((form[field] as string[]).length === 0) return 'null';
    return `"${form[field]}"`;
  } else {
    return 'null';
  }
};

export const POST_ADVANCED_SEARCH = (
  form: JobAdvancedSearchForm,
  first: Number,
  offset: Number,
) => ({
  query: `
    query (
      $jobId: [String]
      $externalId: [String]
      $jobName: String
      $deleted: Boolean
      $state_In: [String]
      $state_NotIn: [String]
      $stringStatus_ContainsIn: [String]
      $stringStatus_NotContainsIn: [String]
      $created_Gte: DateTime
      $created_Lte: DateTime
      $statusLastUpdate_Gte: DateTime
      $statusLastUpdate_Lte: DateTime
      $orderBy: String
    ) {
      jobList(
        first: ${first}
        offset: ${offset}
        jobId: $jobId
        externalId: $externalId
        jobName: $jobName
        deleted: $deleted
        state_In: $state_In
        state_NotIn: $state_NotIn
        stringStatus_ContainsIn: $stringStatus_ContainsIn,
        stringStatus_NotContainsIn: $stringStatus_NotContainsIn,
        created_Gte: $created_Gte,
        created_Lte: $created_Lte,
        statusLastUpdate_Gte: $statusLastUpdate_Gte,
        statusLastUpdate_Lte: $statusLastUpdate_Lte,
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            jobId
            jobName
            deleted
            state
            stringStatus
            jobDifficulty
            externalId
            completeTime
            created
            statusLastUpdate
            sourceLanguage
            originalPriority
          }
        }
      }
    }
  `,
  variables: `{
    "jobId": ${form.jobId},
    "externalId": ${handleNull(form, 'externalId')},
    "jobName": ${handleNull(form, 'jobName')},
    "deleted": ${form.deleted ? 'true' : 'false'},

    "state_In": ${handleNull(form, 'state_In')},
    "state_NotIn": ${handleNull(form, 'state_NotIn')},

    "stringStatus_ContainsIn": ${handleNull(form, 'stringStatus_ContainsIn')},
    "stringStatus_NotContainsIn": ${handleNull(
      form,
      'stringStatus_NotContainsIn',
    )},

    "created_Gte": ${handleNull(form, 'created_Gte')},
    "created_Lte": ${handleNull(form, 'created_Lte')},

    "statusLastUpdate_Gte": ${handleNull(form, 'statusLastUpdate_Gte')},
    "statusLastUpdate_Lte": ${handleNull(form, 'statusLastUpdate_Lte')},

    "first": ${first},
    "offset": ${offset},

    "orderBy": "-created"
  }`,
});
