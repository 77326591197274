import axios, { AxiosResponse } from 'axios';

import { localStash } from 'services/stash';

const isDevEnv = process.env.NODE_ENV !== 'production';

const backend = isDevEnv
  ? 'http://localhost:8000/'
  : 'https://stagging-lupa-api.cielo24.com/';

const url = {
  graphql: `${backend}graphql`,
};

export const api = axios.create({
  baseURL: url.graphql,
});

const token = localStash.getItem('@token');

if (token) {
  api.defaults.headers.common = { Authorization: `JWT ${token}` };
}

api.interceptors.response.use((axiosResponse: AxiosResponse) => {
  const { errors } = axiosResponse.data;

  const errMessage = 'You do not have permission to perform this action';
  if (errors && errors.at(0)?.message === errMessage) {
    console.log('### Logoff');
  }

  return axiosResponse;
});

export const graphql = async <T>(body: {
  query: string;
  variables?: string;
}) => {
  return await api.post<T>('', body);
};
