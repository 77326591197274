import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  align-items: center;

  h3 {
    font-size: ${props => props.theme.fontSize.big};
    margin-left: ${props => props.theme.spacing.margin.small};
  }
`;
