import { ReactNode } from 'react';
import { AiOutlineDashboard } from 'react-icons/ai';
import { BiTimeFive } from 'react-icons/bi';
import { ImHourGlass } from 'react-icons/im';
import { MdShowChart } from 'react-icons/md';

import { LoadingCustom } from 'components/LoadingCustom';

import * as S from './style';

export function DashboardCard(props: DashboardCardProps) {
  const { title, icon_name, total, loading } = props;

  if (loading) {
    return (
      <S.Container style={{ justifyContent: 'center', alignItems: 'center' }}>
        <LoadingCustom medium />
      </S.Container>
    );
  }

  return (
    <S.Container>
      <div className={'info-card'}>
        <h2>{title}</h2>
        <h2>{total || <LoadingCustom small />}</h2>
      </div>
      <div className={'icon-card'}>
        <span>
          <HandleIcon icon_name={icon_name} />
        </span>
      </div>
    </S.Container>
  );
}

interface DashboardCardProps {
  children?: ReactNode;
  loading: boolean;
  title: string;
  icon_name: string;
  total: number;
}

const HandleIcon = ({ icon_name }: { icon_name: string }) => {
  switch (icon_name) {
    case 'MdShowChart':
      return <MdShowChart size={'30'} color={'#6DAE56'} />;
    case 'ImHourGlass':
      return <ImHourGlass size={'26'} color={'#FF6767'} />;
    case 'AiOutlineDashboard':
      return <AiOutlineDashboard size={'30'} color={'#3B84AE'} />;
    case 'BiTimeFive':
      return <BiTimeFive size={'30'} color={'#F4A361'} />;
    default:
      return null;
  }
};
