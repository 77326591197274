/**
 * Login handle routes
 *
 * Every routes has a 'prefix + currentPage'
 *
 * To know where the prefix are, visit:
 * src\routes\index.tsx
 *
 * To more information about route, visit:
 * https://reactrouter.com/docs/en/v6/getting-started/overview
 */
import React, { ReactNode } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { localStash } from 'services/stash';
import { LoginTemplate } from 'components/LoginTemplate';

import { FormLogin } from './FormLogin';
import { ChangePassword } from './ChangePassword';
import { Error } from 'pages/Error';

export function Login(props: LoginProps) {
  const token = localStash.getItem('@token');

  let redirect = 'welcome';

  if (token) {
    redirect = '/dashboard/welcome';
  }

  return (
    <LoginTemplate>
      <Routes>
        <Route path={'/'} element={<Navigate to={redirect} replace />} />
        <Route path={'/welcome'} element={<FormLogin />} />
        <Route path={'/change-password'} element={<ChangePassword />} />
        <Route path={'/*'} element={<Error />} />
      </Routes>
    </LoginTemplate>
  );
}

interface LoginProps {
  children?: ReactNode;
}
