/**
 * Context to State and Function Job sharing
 *
 * Contains:
 * # Handle Request to fecth Job Content
 * # Handle and convert form object to be submitted
 */
import { createContext, ReactNode, useContext, useState } from 'react';
import { addDays, addHours, addMonths, addMinutes, format } from 'date-fns';

import { useGraphQLHandler } from 'contexts/GraphQLHandler';
import { RELATIVES_DATE_MAP } from 'utils/constants';

import {
  JobAdvancedSearchForm,
  JobAdvancedSearchResponse,
  JobsEdges,
  SelectProp,
} from './types';

import * as gql from './queries';

interface JobSearch {
  handleSetCollapsable: (isCollapsable?: boolean) => void;
  handleRequest: (form?: JobAdvancedSearchForm) => void;
  handleCleanList: () => void;
  loading: boolean;
  hasMore: boolean;
  isCollapsable: boolean;
  error: string;
  jobs: JobsEdges[];
}

const FIRST = 25;

const JobSearchContext = createContext<JobSearch>({} as JobSearch);

export function JobSearchProvider({ children }: JobSearchProviderProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [isCollapsable, setIsCollapsable] = useState(false);
  const [jobs, setJobs] = useState<JobsEdges[]>([] as JobsEdges[]);
  const [formSaved, setFormSaved] = useState<JobAdvancedSearchForm>();

  const { graphqlRequest } = useGraphQLHandler();

  function handleSetCollapsable(isCollapsable = false) {
    setIsCollapsable(isCollapsable);
  }

  function handleCleanList() {
    setJobs([] as JobsEdges[]);
    setHasMore(false);
    setFormSaved(undefined);
  }

  async function handleRequest(form?: JobAdvancedSearchForm) {
    setError('');
    setLoading(true);

    try {
      form = form || formSaved;
      if (!form) return;
      setFormSaved(form);

      const handleForm = handleFormConvertoToQuery(form) as any;

      const body = gql.POST_ADVANCED_SEARCH(handleForm, FIRST, jobs.length);

      const response = await graphqlRequest<JobAdvancedSearchResponse>(body);
      console.log('### RESPONSE', response);
      const { edges, pageInfo } = response.data.jobList;

      setJobs(old => [...old, ...edges]);
      setHasMore(pageInfo.hasNextPage);
    } catch (e) {
      console.log('### e', (e as any).message);
      setError((e as any).message);
    } finally {
      setLoading(false);
    }
  }

  const providerValue: JobSearch = {
    loading,
    hasMore,
    error,
    jobs,
    isCollapsable,
    handleSetCollapsable,
    handleRequest,
    handleCleanList,
  };

  return (
    <JobSearchContext.Provider value={providerValue}>
      {children}
    </JobSearchContext.Provider>
  );
}

interface JobSearchProviderProps {
  children?: ReactNode;
}

export function useJobSearch() {
  const context = useContext(JobSearchContext);

  return context;
}

/**
 * Function: handleSelectPropsToStringArray
 *
 * Convert a 'SelectProp' array, to a 'string' JSON array format
 *
 * Example: 1,2,a,b,a1 => ["1","2","a","b","a1"]
 */
function handleSelectPropsToStringArray(arr?: SelectProp[]) {
  if (!arr) return null;
  if (arr.length === 0) return null;
  let result = arr.map(({ label }) => label.trim()).join('","') as any;
  return '["' + result + '"]';
}

/**
 * Function: handleStringToStringArray
 *
 * Convert a 'string' separate by comma, to a 'string' JSON array format
 *
 * Example: 1,2,a,b,a1 => ["1","2","a","b","a1"]
 */
function handleStringToStringArray(arr: string) {
  if (arr?.length === 0) return null;

  let result = arr.split(',').map(val => `"${val.trim()}"`);

  return '[' + result + ']';
}

/**
 * Decides if use Common Date Range or Relative Date Range
 *
 * Logic:
 * If 'form.relativeDate' is defined then use relative date
 * else use common created_Gte and created_Lte values
 *
 * About the logic: All amount of time is convert to positive, so times -1.
 * Function from date-fns works well with negative values to
 * create a created_Gte value
 */
function handleRelativeDate(form: JobAdvancedSearchForm) {
  const _now = new Date();

  if (form.relativeDate) {
    let created_Gte = _now;

    if (form.relativeDateType.value === RELATIVES_DATE_MAP.MINUTES) {
      created_Gte = addMinutes(_now, Math.abs(form.relativeDate) * -1);
    } else if (form.relativeDateType.value === RELATIVES_DATE_MAP.HOURS) {
      created_Gte = addHours(_now, Math.abs(form.relativeDate) * -1);
    } else if (form.relativeDateType.value === RELATIVES_DATE_MAP.DAYS) {
      created_Gte = addDays(_now, Math.abs(form.relativeDate) * -1);
    } else if (form.relativeDateType.value === RELATIVES_DATE_MAP.MONTHS) {
      created_Gte = addMonths(_now, Math.abs(form.relativeDate) * -1);
    }

    return {
      created_Gte: format(created_Gte, "yyyy-MM-dd'T'HH:mm"),
      created_Lte: format(_now, "yyyy-MM-dd'T'HH:mm"),
    };
  }

  return {
    created_Gte: form.created_Gte,
    created_Lte: form.created_Lte,
  };
}

/**
 * Convert the raw form to query values
 *
 * It makes easy filled empty or existing values
 */
function handleFormConvertoToQuery(form: JobAdvancedSearchForm) {
  const created = handleRelativeDate(form);

  let handleForm = {
    ...form,
    jobId: handleStringToStringArray(form.jobId),
    externalId: handleStringToStringArray(form.externalId),
    jobName: handleStringToStringArray(form.jobName),

    state_In: handleSelectPropsToStringArray(form.state_In),
    state_NotIn: handleSelectPropsToStringArray(form.state_NotIn),

    stringStatus_ContainsIn: handleSelectPropsToStringArray(
      form.stringStatus_ContainsIn,
    ),
    stringStatus_NotContainsIn: handleSelectPropsToStringArray(
      form.stringStatus_NotContainsIn,
    ),

    created_Lte: created.created_Lte,
    created_Gte: created.created_Gte,
  };

  return handleForm;
}
