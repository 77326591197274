import { ReactNode, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useDashboard } from 'contexts/Dashboard';

import { DashboardCard } from './DashboardCard';

import * as S from './style';

/* eslint react-hooks/exhaustive-deps: "off" */
export function DashboardPainel(_props: DashboardPainelProps) {
  const [loading, setLoading] = useState(true);

  const [inflightInfo, setInflightInfo] = useState({
    maxJobs: 0,
    maxMinutes: 0,
  });

  const { inflightInfoRequest, lateJobs } = useDashboard();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await inflightInfoRequest();
        setInflightInfo(response.data.inflightInfo);
      } catch (e) {
        toast.error((e as any).message);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  function handleNumberFormat(num: number) {
    return Number(parseFloat(String(num)).toFixed(2));
  }

  const totalLateJobs = lateJobs?.at(0);

  return (
    <S.Container className={'margin-body'}>
      <div className={'dashboard-painel-body'}>
        <DashboardCard
          loading={lateJobs?.length === 0 || !totalLateJobs}
          title={totalLateJobs?.state || ''}
          total={handleNumberFormat(totalLateJobs?.amount || 0)}
          icon_name={'MdShowChart'}
        />
        <DashboardCard
          loading={loading}
          title={'Inflight Jobs'}
          total={handleNumberFormat(inflightInfo.maxJobs)}
          icon_name={'AiOutlineDashboard'}
        />
        <DashboardCard
          loading={loading}
          title={'Inflight Minutes'}
          total={handleNumberFormat(inflightInfo.maxMinutes)}
          icon_name={'BiTimeFive'}
        />
      </div>
    </S.Container>
  );
}

interface DashboardPainelProps {
  children?: ReactNode;
}
