// WIP
import React, { ReactNode } from 'react';

import * as S from './style';

export function LoadingCustom(props: LoadingPropsCustom) {
  const { small, medium, ...rest } = props;

  const css = [small ? 'small' : '', medium ? 'medium' : ''];

  return (
    <S.Container {...rest}>
      <span></span>
      <span className={css.join(' ')}></span>
    </S.Container>
  );
}

interface LoadingPropsCustom extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  small?: boolean;
  medium?: boolean;
}
