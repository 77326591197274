/**
 * This file is contruct to be used by another Context
 *
 * Contains:
 * # Share Function to do request
 * # Check JWT Token
 * # Renew JWT Token, if necessary
 * # Save the JWT on the localStorage
 * # If no valid JWS, with no Renew JWT Token, user is removed from app
 */
import {
  ReactNode,
  useContext,
  useEffect,
  useState,
  createContext,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { api, graphql } from 'services/api';
import { localStash } from 'services/stash';

import { VerifyTokenResponse } from './types';
import * as gql from './queries';

const GraphQLHandlerContext = createContext<GraphQLHandler>(
  {} as GraphQLHandler,
);

const LOGOUT_MESSAGE = 'You do not have permission to perform this action';

/* eslint-disable react-hooks/exhaustive-deps */
export function GraphQLHandlerProvider({
  children,
}: GraphQLHandlerProviderProps) {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    // Loads token from localStorage
    const token = localStash.getItem('@token');

    // If tries to do A REQUEST without token in a DIFFERENT page than login, then goes to login page
    if (pathname.includes('/login/') === false && !token) {
      navigate('/');
    }

    const fetchData = async () => {
      setLoading(true);

      try {
        // Verify if token is valid
        const body = gql.POST_VERIFY_TOKEN(token || '');
        const { data: response } = await graphqlRequest<VerifyTokenResponse>(
          body,
        );

        const exp = response?.verifyToken?.payload?.exp;
        const cur = new Date().getTime();

        // If there is a expiration date, check if it is valid
        if (exp) {
          console.group('Token Time');
          console.log('---');
          console.log('END', exp * 1000, new Date(exp * 1000));
          console.log('CUR', cur, new Date(cur));
          console.log('IS STILL A VALID TOKEN?', exp * 1000 > cur);
          console.log('---');
          console.groupEnd();
        }

        // Add JWT to API, so the next requests will already had
        api.defaults.headers.common = { Authorization: `JWT ${token}` };
      } catch (e) {
        // If fails, clean the token storage and return to login page
        localStash.setItem('@token', '');
        toast.info('Please, do login');
        navigate('/');
      }

      setLoading(false);
    };

    // If has token, trigger the function
    if (token) {
      fetchData();
    }
  }, []);

  async function graphqlRequest<T extends ResponseProps>(
    body: BodyRequestProps,
  ) {
    try {
      // Prepare a post request with type T received
      const { data: response } = await graphql<T>(body);

      const error = response.errors?.at(0);

      // If has error of permission, so user do logoff
      if (error?.message === LOGOUT_MESSAGE) {
        navigate('/');
        toast.error(LOGOUT_MESSAGE);
      } else if (error) {
        // Else, if has any other error. Throws a exception.
        throw new Error(error.message);
      }

      return response;
    } catch (e) {
      console.log('error', (e as any).message);
      throw new Error((e as any).message);
    }
  }

  return (
    <GraphQLHandlerContext.Provider value={{ loading, graphqlRequest }}>
      {children}
    </GraphQLHandlerContext.Provider>
  );
}

type BodyRequestProps = {
  query: string;
  variables?: string;
};

type ResponseProps = { data: any; errors?: { message: string }[] };

interface GraphQLHandlerProviderProps {
  children?: ReactNode;
}

interface GraphQLHandler {
  graphqlRequest: <T extends ResponseProps>(
    body: BodyRequestProps,
  ) => Promise<T>;
  loading: boolean;
}

export function useGraphQLHandler() {
  const context = useContext(GraphQLHandlerContext);

  return context;
}
