// WIP
import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;

  border: ${props => props.theme.border['light-gray']};
  padding: ${props => props.theme.spacing.padding.medium};

  .page_404 {
    font-family: 'Roboto', serif;

    width: 720px;
  }

  .page_404 img {
    width: 100%;
    padding: 20px 0;
  }

  .text-center {
    flex-direction: column;
    align-items: center;
  }

  .four_zero_four_bg h1 {
    display: flex;
    justify-content: center;
    font-size: 80px;
  }

  .four_zero_four_bg h3 {
    font-size: 80px;
  }

  .link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
  }
  .contant_box_404 {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: -50px;
  }

  .four_zero_four_bg {
    background-image: url('https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif');
    height: 400px;
    background-position: center;
  }
`;
