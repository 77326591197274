import styled from 'styled-components';
import { cssTool } from 'styles/cssTool';

export const Container = styled.header`
  display: flex;
  min-height: 112px;

  width: 100%;
  z-index: 900;

  flex-direction: column;

  padding: 0 ${props => props.theme.spacing.padding.big};

  background-color: ${props => props.theme.background['top-navigation']};
  ${cssTool.boxShadow};

  .title-top-side {
    display: flex;
    flex: 6;
    justify-content: space-between;
    align-items: center;
    max-height: 72px;
  }

  .title-bottom-side {
    display: flex;
    flex: 3;

    justify-content: space-between;
    align-items: center;

    border-top: ${props => props.theme.border['light-gray']};

    span {
      color: ${props => props.theme.textColor['card-title']};
      font-size: ${props => props.theme.fontSize.small};
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  .user-menu-side {
    align-items: center;

    & > :first-child {
      margin-right: 5px;
    }
  }

  .title-card {
    justify-content: flex-start;
    align-items: center;

    span {
      display: flex;
      padding: ${props => props.theme.spacing.padding.small};
      justify-content: center;
      align-items: center;

      :hover {
        cursor: pointer;
      }
    }
  }

  .szh-menu-button {
    background-image: url('https://ui-avatars.com/api/?background=random&name=Lupa+v2');
    width: 3rem;
    height: 3rem;

    background-size: cover;

    background-position: top center;

    border-radius: 50%;
  }
`;
