import styled from 'styled-components';
import { StyledWhiteCard } from 'components/StyledWhiteCard';

export const Container = styled(StyledWhiteCard)`
  padding: 2px;

  flex-direction: column;
  margin-bottom: 40px;

  .rdt_TableBody {
    font-family: 'Roboto';
  }
  .rdt_TableHeadRow,
  .rdt_TableRow:not(:last-of-type) {
    font-family: 'Roboto';

    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    border-bottom-color: #eaeaea;
  }
  .rdt_TableRow > div {
    color: #3d3d3d;
  }
  .rdt_ExpanderRow {
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .table-title-info {
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }

  #title-info {
    text-transform: none;
  }
`;
