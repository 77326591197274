/**
 * Create to group the Input Props and make it simple
 *
 * Its is used on "src\pages\Dashboard\Job\index.tsx"
 */
import React from 'react';
import { TextField, StandardTextFieldProps } from '@mui/material';

export function TextFieldCustom(_props: TextFieldCustomProps) {
  const { label, name, formik, ...rest } = _props;

  return (
    <>
      {label && <label htmlFor={name}>{label}:</label>}
      <TextField
        id={name}
        name={name}
        value={formik.values.created_Gte}
        onChange={formik.handleChange}
        error={!!formik.errors.created_Gte}
        helperText={formik.errors.created_Gte}
        {...rest}
      />
    </>
  );
}

interface TextFieldCustomProps extends StandardTextFieldProps {
  name: string;
  label?: string;
  formik: any;
}
