import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;

  padding: ${props => props.theme.spacing.padding.big};

  form > div {
    :nth-child(n):not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }

  .input-row {
    display: flex;
    flex-direction: column;

    & > :last-child {
      margin-right: 0;
    }
  }

  .row {
    display: flex;
    margin-bottom: 0.75rem;

    & > * {
      margin-right: 0.5rem;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }

  .loading-row {
    height: 45px;
  }

  .advanced-row {
    display: flex;
    margin-top: 0.75rem;

    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }

    & > div {
      display: flex;
      flex: 1;

      align-items: center;
      margin-left: 5px;
      margin: 0 ${props => props.theme.spacing.margin.small};

      :first-child {
        margin-left: 0;
      }

      @media only screen and (max-width: 1200px) {
        margin-top: 4px;
        margin-left: 0;
      }

      & > * {
        margin-right: 4px;
      }

      label {
        /* text-overflow: ellipsis; */
        /* overflow: hidden; */
        white-space: nowrap;
        margin-right: 0.75rem;
      }
    }
  }

  .top-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @media only screen and (max-width: 1200px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
