import { Navigate, Route, Routes } from 'react-router-dom';

import { DashboardTemplate } from 'components/DashboardTemplate';
import { Error } from 'pages/Error';

import { Main } from './Main';
import { Job } from './Job';

export function Dashboard() {
  return (
    <DashboardTemplate>
      <Routes>
        <Route path={'/'} element={<Navigate to={'welcome'} replace />} />
        <Route path={'/welcome'} element={<Main />} />
        <Route path={'/job'} element={<Job />} />
        <Route path={'/*'} element={<Error />} />
      </Routes>
    </DashboardTemplate>
  );
}
