import styled from 'styled-components';

export const Container = styled.div`
  height: 400px;

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
