import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  border: ${props => props.theme.border['light-gray']};
  border-radius: ${props => props.theme.border.radius};
  background-color: ${props => props.theme.background['card-body']};
  box-shadow: ${props => props.theme.border['card-shadow']};

  padding: ${props => props.theme.spacing.padding.big};

  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: ${props => props.theme.spacing.margin.medium};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.textColor.title};
    text-transform: uppercase;

    text-overflow: ellipsis; /* IE, Safari (WebKit) */
    overflow: hidden; /* don't show excess chars */
    white-space: nowrap; /* force single line */
  }

  @media only screen and (min-width: 1200px) {
    padding: ${props =>
      props.theme.spacing.padding.medium +
      ' ' +
      props.theme.spacing.padding.big};
  }

  @media only screen and (min-width: 1600px) {
    padding: ${props =>
      props.theme.spacing.padding.medium +
      ' ' +
      props.theme.spacing.padding.big};
  }

  @media only screen and (min-width: 600px) and (max-width: 768px) {
    padding: ${props =>
      props.theme.spacing.padding.medium +
      ' ' +
      props.theme.spacing.padding.huge};
  }

  @media only screen and (max-width: 600px) {
    padding: ${props =>
      props.theme.spacing.padding.medium +
      ' ' +
      props.theme.spacing.padding.big};
  }
`;
