/**
 * Creates a side bar navigation
 *
 * Contains:
 * # Routes
 * # Toogle Option
 *
 * source: https://github.com/azouaoui-med/react-pro-sidebar#screenshot
 */
import { useRef } from 'react';
import { useTheme } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { AiOutlineHome, AiOutlineSearch } from 'react-icons/ai';
import { RiAliensLine } from 'react-icons/ri';
import { MdSpaceDashboard } from 'react-icons/md';

import {
  ProSidebar,
  Menu,
  MenuItem,
  // SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

import { useSideBar } from 'contexts/SideBar';
import { useOutsideComponentClickHandle } from 'hooks/useOutsideComponentClickHandle';

import * as S from './style';

export function SideBarMenu(props: SideBarMenuProps) {
  const { isOpened, toogleSideBar } = useSideBar();

  const ref = useRef();

  const { color } = useTheme();

  /** This ref is Used to control outside click, then close the sidebar */
  useOutsideComponentClickHandle(ref, () => {});

  return (
    <S.Container sideBarIsOpen={isOpened}>
      <ProSidebar collapsed={!isOpened} ref={ref}>
        <SidebarHeader>
          <Menu>
            <MenuItem
              icon={<MdSpaceDashboard size={36} color={color.primary} />}
              onClick={toogleSideBar}
            >
              <strong>Lupa Dash</strong>
            </MenuItem>
          </Menu>
        </SidebarHeader>

        <SidebarContent>
          <Menu
            className={'pro-sidebar-layout pro-sidebar-header'}
            innerSubMenuArrows
          >
            <MenuItem
              icon={<AiOutlineHome size={18} color={color['sidebar-icon']} />}
            >
              <NavLink
                to="/dashboard/welcome"
                className={({ isActive }) => (isActive ? 'nav-selected' : '')}
              >
                <span>Dashboard</span>
              </NavLink>
            </MenuItem>

            <MenuItem
              icon={<AiOutlineSearch size={18} color={color['sidebar-icon']} />}
            >
              <NavLink
                to="/dashboard/job"
                className={({ isActive }) => (isActive ? 'nav-selected' : '')}
              >
                <span>Search</span>
              </NavLink>
            </MenuItem>
          </Menu>
        </SidebarContent>

        <SidebarFooter>
          {process.env.NODE_ENV !== 'production' && (
            <RiAliensLine color={'lightgreen'} size={22} />
          )}
        </SidebarFooter>
      </ProSidebar>
    </S.Container>
  );
}

interface SideBarMenuProps {
  children?: React.ReactNode;
}
