import styled from 'styled-components';
import { cssTool } from 'styles/cssTool';

export const Container = styled.div`
  display: flex;
  flex: 1;

  justify-content: center;
  align-items: center;

  background-image: ${props => props.theme.gradient['login-background']};

  @media only screen and (max-width: 600px) {
    align-items: flex-start;
    padding-top: 4rem;
  }

  .login-box {
    ${cssTool.boxShadow};

    background-color: ${props => props.theme.background['card-icon']};
    border: ${props => props.theme.border['light-gray']};
    border-radius: ${props => props.theme.border.radius};

    padding: ${props => props.theme.spacing.padding.huge};

    @media only screen and (max-width: 600px) {
      padding: ${props => props.theme.spacing.padding.medium};
    }
  }
`;
