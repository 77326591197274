// WIP
import styled from 'styled-components';
import { cssTool } from 'styles/cssTool';

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1;

  align-items: center;
  justify-content: center;

  .small {
    height: 1.5rem !important;
    width: 1.5rem !important;
    box-shadow: 1px 0.5px 1px ${props => props.theme.color.secondary} !important;
  }

  .medium {
    height: 2.5rem !important;
    width: 2.5rem !important;
    box-shadow: 1px 1px 1px ${props => props.theme.color.secondary} !important;
  }

  span:nth-child(1) {
    color: black;
  }

  span:nth-child(2) {
    position: absolute;
    border-radius: 50%;

    height: 120px;
    width: 120px;

    background: transparent;
    animation: animate_loading 1s linear infinite;

    ${cssTool.boxShadow};
  }

  @keyframes animate_loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
