import { ChangePasswordForm, LoginForm } from './types';

export const POST_LOGIN = (form: LoginForm) => ({
  query: `
    mutation ($email: String!, $password: String!) {
      tokenAuth(email: $email, password: $password) {
        payload
        token

        user {
          isNew
          isSuperuser
          name
          email
        }
      }
    }
  `,
  variables: `{
    "email": "${form.user}",
    "password": "${form.pass}"
  }`,
});

export const POST_CHANGE_PASSWORD = (form: ChangePasswordForm) => ({
  query: `
    mutation ($oldPassword: String!, $newPassword: String!) {
      changeUserPassword (oldPassword: $oldPassword, newPassword: $newPassword) {
        success
        token
      }
    }
  `,
  variables: `{
    "newPassword": "${form.newPassword}",
    "oldPassword": "${form.oldPassword}"
  }`,
});

export const POST_REFRESH_TOKEN = ({ token }: { token: string }) => ({
  query: `
    mutation ($token: String!) {
      refreshToken (token: $token) {
        payload
        refreshExpiresIn
        token
      }
    }
  `,
  variables: `{
    "token": "${token}"
  }`,
});
