/**
 * This file is used as a table cell
 *
 * Show the string content, with hover and copy feature
 * If the string is to long, then it is sliced
 */
import React from 'react';

import { useWindowSize } from 'hooks/useWindowSize';
import { CopyContent } from 'components/CopyContent';
import { WrapReactTooltip } from 'components/WrapReactTooltip';

export function FormatedCell({ maxLength = 9, content }: FormatedCellProps) {
  const _id = String(Math.random());

  const { width } = useWindowSize();

  function handleEllipseContent() {
    if (width && width < 600) return content.slice(0, 7) + '...';
    if (width && width < 1200) return content.slice(0, maxLength) + '...';
    return content;
  }

  return (
    <>
      <CopyContent data-tip data-for={_id} content={content}>
        <span>{handleEllipseContent()}</span>
      </CopyContent>
      <WrapReactTooltip id={_id} type={'info'}>
        <div className={'hover-title'}>
          <strong>{content}</strong>
        </div>
      </WrapReactTooltip>
    </>
  );
}

interface FormatedCellProps {
  maxLength?: number;
  content: string;
}
