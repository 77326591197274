import styled, { css } from 'styled-components';

interface ContainerProps {
  sideBarIsOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 1;

  background-color: ${props => props.theme.background['card-icon']};
  overflow: none;
  overflow-x: none;

  .card {
    background-color: ${props => props.theme.background['card-body']};
    border: ${props => props.theme.border['light-gray']};
  }

  .padding-body {
    padding: ${props => props.theme.spacing.padding.big};

    @media only screen and (max-width: 1600px) {
      padding: ${props => props.theme.spacing.padding.medium};
    }

    @media only screen and (max-width: 768px) {
      padding: ${props => props.theme.spacing.padding.small};
    }
  }

  .margin-body {
    margin: ${props => props.theme.spacing.margin.big};

    @media only screen and (max-width: 1600px) {
      margin: ${props => props.theme.spacing.margin.medium};
    }

    @media only screen and (max-width: 768px) {
      margin: ${props => props.theme.spacing.margin.small};
    }
  }

  main {
    display: flex;
    flex-direction: column;
    flex: 1;

    width: calc(100vh - 80px);

    ${props =>
      props.sideBarIsOpen &&
      css`
        width: calc(100vh - 270px);
      `}

    margin-left: 80px;
    transition: margin-left 0.4s;

    @media only screen and (max-width: 600px) {
      margin-left: 0px;
    }

    & > div {
      display: flex;

      overflow: hidden;
      overflow-y: auto;

      padding: ${props => props.theme.spacing.padding.small};
    }
  }

  .extended {
    margin-left: 270px;
    transition: margin-left 0.2;

    @media only screen and (max-width: 600px) {
      ${props =>
        props.sideBarIsOpen &&
        css`
          margin-left: 0px;
        `}
    }
  }
`;
