/**
 * This file most to be created because some conflicts
 * between typescript with unsupported lib.
 *
 * There are 2 that kind:
 * WrapChart
 * WrapReactTooltip
 */
import React from 'react';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

const Wrap = Chart as any;

export function WrapApexCharts(_props: WrapReactApexCharts) {
  const { ...rest } = _props;

  return <Wrap style={{ fill: 'none' }} {...rest} />;
}

interface WrapReactApexCharts {
  type?:
    | 'line'
    | 'area'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar';
  series?: ApexOptions['series'];
  width?: string | number;
  height?: string | number;
  options?: ApexOptions;
  [key: string]: any;
}
