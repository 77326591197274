import { useEffect } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AppRoutes } from 'routes';

function App() {
  useEffect(() => {
    console.log(
      'RUNNING...',
      process.env.NODE_ENV === 'production' ? 'PRODUCTION' : 'DEVELOPMENT',
    );
  }, []);

  return (
    <>
      <ToastContainer />
      <AppRoutes />
    </>
  );
}

export default App;
