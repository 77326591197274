// WIP
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .dashboard-painel-body {
    display: flex;
    flex-wrap: wrap;

    gap: ${props => props.theme.spacing.padding.big};
  }
`;
