/**
 * Dashboard Right Column - Late Jobs
 *
 * This file show charts as per Backend's answer
 *
 * If there is a result on response, shows only one chart
 * If there are a list of 10 results on response, shows 10 charts
 */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';

import { useDashboard } from 'contexts/Dashboard';
import { LoadingCustom } from 'components/LoadingCustom';

import { SimpleChart } from './SimpleChart';

import * as S from './style';

/* eslint-disable react-hooks/exhaustive-deps */
export function DashboardLateJobs(_props: DashboardLateJobsProps) {
  const [loading, setLoading] = useState(true);

  const componentMounted = useRef(true);

  const [lateJobs, setLateJobs] = useState<
    {
      state: string;
      range: string;
      amount: number;
    }[]
  >([]);

  const { lateJobsRequest } = useDashboard();

  useEffect(() => {
    handleLateJobsRequest();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  async function handleLateJobsRequest() {
    setLoading(true);

    try {
      const { data: response } = await lateJobsRequest();

      if (componentMounted.current) {
        setLateJobs(response.lateJobs.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toast.error((e as any).message);
    }
  }

  const rangeSet = lateJobs.map(e => e.range).filter(onlyUnique);

  return (
    <S.Container className={'margin-body card'}>
      <div className={'late-job-title'}>
        <h2>Late Jobs</h2>
        <div>
          {!loading && (
            <Button
              variant={'contained'}
              size={'small'}
              onClick={handleLateJobsRequest}
            >
              Refresh
            </Button>
          )}
        </div>
      </div>
      <div className={loading ? 'loading-box' : 'dashboard-column'}>
        {loading ? (
          <LoadingCustom medium />
        ) : (
          rangeSet.map(range => {
            return (
              <SimpleChart
                rangeTitle={range}
                lateJobs={lateJobs.filter(late => late.range === range)}
                key={range}
              />
            );
          })
        )}
      </div>
    </S.Container>
  );
}

function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

interface DashboardLateJobsProps {
  children?: ReactNode;
}
