import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  align-items: center;

  svg {
    margin: 0 2px;
  }

  svg:hover {
    cursor: pointer;
  }
`;
