import styled from 'styled-components';

export const ContainerDonuts = styled.div`
  display: flex;
  flex-direction: column;

  .donuts-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div > :nth-child(n) {
      margin-left: 10px;
    }
  }

  .loading-padding {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    aspect-ratio: 16 / 9;
  }

  .donuts-body {
    display: flex;
    flex: 1;
    align-items: center;

    & > div:nth-child(1) {
      width: 100%;
      margin: ${props => props.theme.spacing.margin.medium} 0;
    }

    .small-donuts {
      display: none;

      @media only screen and (min-width: 1200px) {
        display: block;
      }
    }

    .big-donuts {
      display: none;

      @media only screen and (max-width: 1200px) {
        display: block;
      }
    }

    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }
`;
