import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { MdSpaceDashboard } from 'react-icons/md';

import * as S from './style';

export function LupaLogo(props: LupaLogoProps) {
  const navigate = useNavigate();
  const { color } = useTheme();

  return (
    <S.Container onClick={() => navigate('/')} {...props}>
      <MdSpaceDashboard size={36} color={color.primary} />
      <h3>Lupa</h3>
    </S.Container>
  );
}

interface LupaLogoProps {
  children?: ReactNode;
}
