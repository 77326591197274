import styled from 'styled-components';
import { StyledWhiteCard } from 'components/StyledWhiteCard';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: ${props => props.theme.spacing.margin.big};
`;

export const Card = styled(StyledWhiteCard)`
  display: flex;
  flex-direction: column;

  flex: 1 1 420px;
`;
