import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 100%;

  gap: 1.5rem;

  grid-auto-rows: min-content;

  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(minmax(1fr, 2), 1fr);

  @media only screen and (max-width: 1600px) {
    grid-template-columns: repeat(1, 1fr);
  }

  /**
   * CSS Override;
   * Source: https://react-data-table-component.netlify.app/?path=/docs/api-css-escape--page
  */
  .rdt_TableRow {
    &:hover {
      cursor: pointer;
      filter: brightness(0.95);
    }
  }
`;
