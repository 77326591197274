import styled, { css } from 'styled-components';

interface ContainerProps {
  sideBarIsOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  height: 100%;

  z-index: 1000;

  strong {
    color: ${props => props.theme.textColor['side-bar']};
    font-size: ${props => props.theme.fontSize.big};
  }

  span {
    color: ${props => props.theme.textColor['side-bar']};
  }

  .nav-selected {
    color: ${props => props.theme.color.secondary} !important;
  }

  .nav-selected .pro-sidebar > .pro-sidebar-inner {
    color: ${props => props.theme.color.secondary} !important;
    background-color: ${props => props.theme.color.secondary};
    filter: brightness(0.95);
  }

  .pro-sidebar > .pro-sidebar-inner {
    background-color: ${props => props.theme.color.secondary};
    filter: brightness(0.95);
  }

  .pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper {
    background-color: ${props => props.theme.background['card-body']};
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    .pro-icon-wrapper {
      .pro-icon {
        animation: none;
        transition: none;
      }
    }
  }

  .pro-sidebar .pro-menu .pro-menu-item:hover {
    background-color: ${props => props.theme.color.secondary};
    filter: brightness(0.9);
  }

  .side-bar-title {
    padding: 1rem;
  }

  .pro-inner-list-item {
    background-color: ${props => props.theme.color.secondary} !important;
    filter: brightness(1.08);
  }

  .pro-sidebar-footer {
    padding: ${props => props.theme.spacing.padding.medium};
  }

  @media only screen and (max-width: 600px) {
    display: none;

    @media only screen and (max-width: 600px) {
      ${props =>
        props.sideBarIsOpen &&
        css`
          display: block;
        `}
    }
  }
`;
