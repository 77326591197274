import { createContext, ReactNode, useContext, useState } from 'react';

import { useGraphQLHandler } from 'contexts/GraphQLHandler';

import {
  ActiveJobsPerStateResponse,
  ActiveJobsPerSubStateResponse,
  BackgroundActionsResponse,
  CreatedJobsTimelineResponse,
  InflightInfoResponse,
  LateJobsResponse,
  ReturnedJobsTimelineResponse,
} from './types';

import * as gql from './queries';

interface Dashboard {
  inflightInfoResponse?: InflightInfoResponse;
  inflightInfoRequest: () => Promise<InflightInfoResponse>;
  activeJobsPerStateRequest: () => Promise<ActiveJobsPerStateResponse>;
  returnedJobsTimeline: (day: number) => Promise<ReturnedJobsTimelineResponse>;
  createdJobsTimeline: (day: number) => Promise<CreatedJobsTimelineResponse>;
  lateJobsRequest: () => Promise<LateJobsResponse>;
  lateJobs?: LateJob[];

  backgroundActionsTimelineRequest: (
    hours: number,
  ) => Promise<BackgroundActionsResponse>;
  activeJobsPerSubStateRequest: (
    substate: string,
  ) => Promise<ActiveJobsPerSubStateResponse>;
}

const DashboardContext = createContext<Dashboard>({} as Dashboard);

export function DashboardProvider({ children }: DashboardProviderProps) {
  const [inflightInfoResponse, setInflightInfoResponse] =
    useState<InflightInfoResponse>();

  const [lateJobs, setLateJobs] = useState<LateJob[]>();

  const { graphqlRequest } = useGraphQLHandler();

  async function inflightInfoRequest() {
    const body = gql.POST_INFLIGHT_INFO();
    const response = await graphqlRequest<InflightInfoResponse>(body);

    const error = response.errors?.at(0);
    if (error) throw new Error(error.message);

    setInflightInfoResponse(inflightInfoResponse);

    return response;
  }

  async function activeJobsPerStateRequest() {
    const body = gql.POST_ACTIVE_JOBS_PER_STATE();
    const response = await graphqlRequest<ActiveJobsPerStateResponse>(body);

    return response;
  }

  async function activeJobsPerSubStateRequest(substate: string) {
    const body = gql.POST_ACTIVE_JOBS_PER_SUB_STATE(substate);
    const response = await graphqlRequest<ActiveJobsPerSubStateResponse>(body);

    return response;
  }

  async function returnedJobsTimeline(days: number) {
    const body = gql.POST_RETURNED_JOBS_TIMELINE(days);
    const response = await graphqlRequest<ReturnedJobsTimelineResponse>(body);

    return response;
  }

  async function createdJobsTimeline(days: number) {
    const body = gql.POST_CREATED_JOBS_TIMELINE(days);
    const response = await graphqlRequest<CreatedJobsTimelineResponse>(body);

    return response;
  }

  async function lateJobsRequest() {
    const body = gql.POST_LATE_JOBS();
    const response = await graphqlRequest<LateJobsResponse>(body);

    const { data } = response;

    const totalLateJobs = data.lateJobs.data.reduce(
      (previousValue, { range, amount }) =>
        range !== 'TBLATE' ? previousValue + amount : previousValue,
      0,
    );

    setLateJobs([
      {
        state: 'Late Jobs',
        amount: totalLateJobs,
        range: 'TOTAL',
      },
    ]);

    return response;
  }

  async function backgroundActionsTimelineRequest(hours: number) {
    const body = gql.POST_BACKGROUND_ACTIONS_TIMELINE(hours);
    const response = await graphqlRequest<BackgroundActionsResponse>(body);

    return response;
  }

  return (
    <DashboardContext.Provider
      value={{
        inflightInfoResponse,
        activeJobsPerStateRequest,
        activeJobsPerSubStateRequest,
        inflightInfoRequest,
        returnedJobsTimeline,
        createdJobsTimeline,
        lateJobsRequest,
        backgroundActionsTimelineRequest,
        lateJobs,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

interface DashboardProviderProps {
  children?: ReactNode;
}

export function useDashboard() {
  const context = useContext(DashboardContext);

  return context;
}

type LateJob = {
  state: string;
  range: string;
  amount: number;
};
