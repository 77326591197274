import React, { ReactNode } from 'react';

import * as S from './style';

export function StyledWhiteCard(props: StyledWhiteCardProps) {
  const { children, ...rest } = props;

  return <S.Container {...rest}>{children}</S.Container>;
}

interface StyledWhiteCardProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}
