/**
 * File shows BackgroundActions List
 *
 * Contains:
 * # Table config / columns configs / sorting / pagination
 * # Chart to show a clear picture
 * # Hover chart
 */
import React, { ReactNode } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { LineSeries, XYPlot } from 'react-vis';
import 'react-vis/dist/style.css';

import * as S from './style';

export function ListBackgroundActions(props: ListBackgroundActionsProps) {
  const { actionsList, ...rest } = props;

  // Config columns for react-vis chart
  const columnsOptions: TableColumn<BackgroundActionItem>[] = [
    {
      name: 'Issues',
      selector: ({ issue }: BackgroundActionItem) => issue,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: ({ amount }: BackgroundActionItem) => amount,
      sortable: true,
      compact: true,
      format: ({ amount }: BackgroundActionItem) =>
        `${Number(parseFloat(`${amount}`).toFixed(1))}`,
    },
    {
      name: 'Chart',
      selector: ({ dataChart }: BackgroundActionItem) => dataChart.join(', '),
      format: ({ dataChart, issue }: BackgroundActionItem) => (
        <InnerChart data={dataChart} issue={issue} />
      ),
    },
  ];

  return (
    <S.Container {...rest}>
      <DataTable
        columns={columnsOptions}
        data={actionsList}
        striped
        defaultSortFieldId={2}
        defaultSortAsc={false}
      />
    </S.Container>
  );
}

const InnerChart = (props: {
  issue: string;
  data: ChartBackgroundActionData[];
}) => {
  const { data, issue } = props;

  const dataForChart = data.map(e => {
    return {
      x: new Date(e.time).getTime(),
      y: e.amount,
    };
  });

  return (
    <div data-tip data-for={issue} className={'small-chart'}>
      <XYPlot xType={'time'} width={250} height={100}>
        <LineSeries data={dataForChart} />
      </XYPlot>
    </div>
  );
};

interface ListBackgroundActionsProps {
  children?: ReactNode;
  actionsList: BackgroundActionItem[];
}

type BackgroundActionItem = {
  issue: string;
  amount: number;
  dataChart: ChartBackgroundActionData[];
};

type ChartBackgroundActionData = {
  time: string;
  amount: number;
};
