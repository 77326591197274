import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';

import { copyContent } from 'utils';
import { useDashboard } from 'contexts/Dashboard';
import { LoadingCustom } from 'components/LoadingCustom';

import { ListBackgroundActions } from './ListBackgroundActions';

import * as S from './style';

/* eslint react-hooks/exhaustive-deps: "off" */
export function BackgroundActions(_props: BackgroundActionsProps) {
  const componentMounted = useRef(true);

  const [loading, setLoading] = useState(true);

  /**
   * Accepts any property name with a BackgroundActionItem[] type
   */
  const [customResponse, setCustomResponse] = useState<CustomResponseType>();

  const { backgroundActionsTimelineRequest } = useDashboard();

  useEffect(() => {
    handleBackgroundActionsTimelineRequest();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  /**
   * Trigger the request by hours
   *
   * Ends the process if component is Unmonted
   */
  async function handleBackgroundActionsTimelineRequest(hours: number = 24) {
    setLoading(true);

    try {
      const { data: response } = await backgroundActionsTimelineRequest(hours);

      // Is the component mounted?
      if (componentMounted.current) {
        setCustomResponse(response.backgroundActionsTimeline);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toast.warn((e as any).message);
    }
  }

  if (loading) {
    return (
      <InnerTableWrap loading={loading}>
        <LoadingCustom medium />
      </InnerTableWrap>
    );
  }

  const raw = customResponse?.data || {};

  /**
   * Its convert and prepare answer, to be shown later
   */
  const data = Object.entries(raw).map(([key, value]) => {
    const _data = Object.entries(value);

    const sum = _data.reduce(
      (partial, [, amount]) => partial + Number(amount),
      0,
    );
    const dataChart = _data.map(([time, amount]) => ({ time, amount }));

    return {
      issue: key,
      amount: sum,
      dataChart: dataChart,
    };
  });

  let globalCount = 1;

  /**
   * Create the copy content.
   *
   * Convert the objects to rows, after this, send the title and data to be copied
   */
  function handleCopyContent() {
    const _data = Object.entries(raw);

    // Convert the objects to rows
    const result = _data.map(([key, value]) => {
      let titleContent = key;

      const content = Object.entries(value).map(([time, amount]) => {
        return [globalCount++, titleContent, time, amount];
      });

      return content;
    });

    // DOC: result.flat() removes subarrays, creates only one array level
    copyContent(result.flat(), ['#', 'Issue', 'Datatime', 'Amount']);

    toast.success('Content copied');
  }

  return (
    <InnerTableWrap
      loading={loading}
      handleCopyContent={handleCopyContent}
      handleBackgroundActionsTimelineRequest={
        handleBackgroundActionsTimelineRequest
      }
    >
      <ListBackgroundActions actionsList={data} />
    </InnerTableWrap>
  );
}

/**
 * Split body content (List and Charts) from top content (Buttons and Title)
 */
const InnerTableWrap = ({ children, ...rest }: any) => {
  const {
    loading,
    handleBackgroundActionsTimelineRequest: handle,
    handleCopyContent,
  } = rest;

  return (
    <S.Container>
      <div className={'title-row'}>
        <h2>Background Actions</h2>
        <div style={loading ? { display: 'none' } : {}}>
          <Button
            variant={'outlined'}
            size={'small'}
            onClick={handleCopyContent}
          >
            Copy
          </Button>
          <Button
            variant={'contained'}
            size={'small'}
            style={{ marginLeft: 5 }}
            onClick={() => handle && handle()}
          >
            Refresh
          </Button>
        </div>
      </div>
      <div className={loading ? 'loading-frame' : 'background-list'}>
        {children}
      </div>
    </S.Container>
  );
};

interface BackgroundActionsProps {
  children?: ReactNode;
}

type CustomResponseType = {
  data: {
    [k: string]: {
      [l: string]: number;
    };
  };
};
