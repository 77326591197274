import { ReactNode } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';

import { GeneralContextProvider } from 'contexts';

import { Dashboard } from 'pages/Dashboard';
import { Error } from 'pages/Error';
import { Login } from 'pages/Login';

import { Container } from './style';

export function AppRoutes(_props: AppRoutesProps) {
  return (
    <BrowserRouter>
      <GeneralContextProvider>
        <Container>
          <Routes>
            <Route path={'/'} element={<Navigate to={'/login'} replace />} />

            <Route path={'/login/*'} element={<Login />} />

            <Route path={'/dashboard/*'} element={<Dashboard />} />

            <Route path={'/error'} element={<Error />} />

            <Route path="/*" element={<Navigate to="/" replace />} />
          </Routes>
        </Container>
      </GeneralContextProvider>
    </BrowserRouter>
  );
}

interface AppRoutesProps {
  children?: ReactNode;
}
